@import '../../../styles/shared.scss';

.favorite-outer-container {
  position: relative;
  .favorite {
    cursor: pointer;
    opacity: 0.5;
    transition: transform 0.3s, opacity 0.3s;

    &:hover {
      opacity: 1;
    }

    &.favorited {
      opacity: 1;
    }

    &.loading {
      opacity: 0.1 !important;
    }
  }
}

@import '../../styles/shared.scss';

.taste-profile-similar-curators-container {
  @extend %flex-col;
  margin-top: 64px;

  .similar-curators-header-container {
    @extend %flex-col-center;
    margin-top: 48px;

    .similar-curators-header {
      font-size: 24px;
      text-align: center;

      @include md {
        font-size: 30px;
      }
    }
    .similar-curators-subheader {
      font-size: 14px;
      text-align: center;
      color: $medium;

      @include md {
        font-size: 16px;
      }
    }
  }

  .similar-curators {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-top: 24px;

    @include sm {
      grid-template-columns: repeat(2, 1fr);
    }
    @include md {
      grid-template-columns: repeat(3, 1fr);
    }
    @include lg {
      grid-template-columns: repeat(4, 1fr);
    }
    @include xl {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .get-next-page-outer {
    @extend %flex-row-center;
    margin-top: 48px;

    .get-next-page {
      @extend %btn-basic;
      display: flex;
      justify-content: center;
      font-size: 16px;
      color: $medium;
    }
  }
}

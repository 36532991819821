@import '../../../styles/shared.scss';

.profile-data-panel-outer-container {
  @extend %flex-col-center;
  font-family: $font-body;

  width: calc(100% - 48px);
  @include md {
    width: 440px;
  }
  @include lg {
    width: 540px;
  }

  .profile-data-panel {
    @extend %flex-col-center;
    position: relative;
    width: 100%;

    .profile-image {
      margin-bottom: 16px;
    }

    .profile-name {
      @extend %flex-row-middle;
      color: $darker;
      font-family: $font-header;
      font-weight: bold;
      letter-spacing: -0.2px;
      margin-bottom: 8px;

      h1 {
        font-size: 22px;
        margin: 0;

        @include md {
          font-size: 24px;
        }
      }

      svg {
        font-size: 14px;
        margin: 4px 0 0 4px;
        cursor: pointer;
      }
    }

    .profile-username {
      align-self: center;
      font-family: $font-body;
      font-size: 16px;
      color: $darker;
      background: $offwhite;
      transition: all 0.4s ease-in-out;
      padding: 4px 12px;
      border: $border-white;
      border-radius: 24px;

      &.empty {
        min-width: 160px;
        min-height: 28px;
        background: $whynotusewhite;
        color: $whynotusewhite;
      }
    }

    .main-data-container {
      @extend %flex-col-center;
      border: $border-lightest;
      padding: 24px 16px;
      margin-top: 24px;
      position: relative;
      border-radius: 4px;
      width: 100%;
      transition: all 0.2s ease-in-out;
      opacity: 1;
      background: white;

      &.empty {
        opacity: 0.3;
      }

      &.no-description {
        border: none;
        padding: 0;
        margin: 0;

        .profile-social-links-icons-container {
          position: relative;
          margin: -4px 0 0;

          .profile-social-links-icon {
            img,
            svg {
              font-size: 14px;
              height: 24px;
            }
          }
        }
        .main-data-title-badge-container {
          position: relative;
          margin-top: -8px;
        }
      }

      .main-data-title-badge-container {
        @extend %flex-center;
        position: absolute;
        width: 100%;
        top: -14px;
        left: 0;

        .main-data-title-badge {
          @extend %flex-row-middle;
          justify-content: center;
          background: white;
          padding: 0 12px;
          color: $darker;
          font-family: $font-header;
          font-weight: bold;
          letter-spacing: 0.6px;

          &.large-name {
            margin-bottom: -30px;
            white-space: nowrap;
            text-align: center;

            @include md-down {
              width: 80vw;
              overflow: hidden;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              display: -webkit-box;
              h1 {
                font-size: 14px;
              }
            }

            h1 {
              font-size: 18px;
            }

            @include sm {
              margin-bottom: 0;

              h1 {
                font-size: 24px;
              }
            }
          }

          h1 {
            font-size: 22px;
            margin: 0;

            @include xs {
              font-size: 24px;
            }

            &.empty {
              @extend %skeleton-box-whynotusewhite;
              min-width: 240px;
              min-height: 32px;
              border-radius: 4px;
            }
          }

          svg {
            font-size: 14px;
            margin: 4px 0 0 4px;
            cursor: pointer;
          }
        }
      }

      .main-data-body {
        color: $darker;
        font-size: 14px;
        text-align: left;
        margin: 0;
        font-weight: normal;

        &.empty {
          min-height: 80px;
        }
      }

      .profile-social-links-icons-container {
        @extend %flex-row;
        align-items: flex-start;
        margin-top: 12px;
        // margin-right: -12px;
        position: absolute;
        bottom: -8px;
        background: white;
        padding: 0 12px;

        .profile-social-links-icon {
          @extend %flex-row-middle;
          color: $medium;
          cursor: pointer;
          margin-right: 8px;

          @include md {
            margin-right: 6px;
          }

          img,
          svg {
            color: $darker;
            opacity: 1;
            object-fit: contain;
            font-size: 14px;
            height: 14px;

            @include sm {
              font-size: 13px;
              height: 13px;
            }
          }

          &.large {
            // For youtube compliance
            img,
            svg {
              font-size: 20px;
              height: 20px;
            }
          }

          &:hover {
            opacity: 0.8;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .profile-link {
      font-family: $font-body;
      font-size: 14px;
      text-align: center;
      margin-top: 4px;
      color: $secondary-dark;
      font-weight: bold;
      font-style: italic;
    }
  }

  .profile-data-panel.edit {
    @extend %flex-col;
    width: 100%;

    .new-name-input {
      @extend %input-shared;
      width: 100%;
    }
    .new-description-input {
      @extend %input-shared;
      margin-top: 12px;
      width: 100%;
    }

    .profile-social-links-edit-panel {
      @extend %flex-col;
      align-self: flex-start;
      max-width: 100%;
      width: 100%;
      margin-top: 12px;

      .current-social-link {
        @extend %flex-row-middle-spread;
        margin-bottom: 12px;
        font-size: 16px;
        color: $darker;
        font-weight: bold;
        position: relative;
        overflow: hidden;
        white-space: nowrap;

        .current-social-link-remove-icon {
          @extend %flex-center;
          font-size: 18px;
          margin-left: 4px;
          color: $darker;
          cursor: pointer;
          position: absolute;
          background: white;
          padding-left: 8px;
          right: 0;

          &:hover {
            opacity: 0.8;
          }
        }

        .link-container {
          @extend %flex-row-middle;
          width: 100%;

          .link-example {
            height: 18px;
            width: 28px;
            object-fit: contain;
            margin-right: 4px;
            color: $medium;
          }

          form {
            width: 100%;
            outline-style: relative;

            input {
              @extend %input-shared;
              font-size: 16px;
              color: $darker;
              width: 100%;
            }

            .current-social-link-add-icon {
              @extend %flex-center;
              position: absolute;
              right: 4px;
              top: 6px;
              background: $darker;
              color: white;
              z-index: 2;
              width: 24px;
              height: 24px;
              border-radius: 12px;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }
            }
          }

          .current-social-link-url {
            text-overflow: ellipsis;
            overflow: hidden;
            width: calc(100% - 53px);
          }
        }
      }
    }

    .btn {
      padding: 8px;
      align-self: stretch;

      &.done-btn {
        @extend %btn-darker;
        margin-bottom: 12px;
      }

      &.cancel-btn {
        @extend %btn-text;
      }

      &.disabled {
        @extend %btn-inactive;
        opacity: 0.2;

        &:hover {
          opacity: 0.2;
        }
      }
    }
  }
}

@import '../../styles/shared.scss';

.taste-profile-header-container {
  @extend %flex-row-middle;
  justify-content: space-around;
  align-items: stretch;
  background-color: $white;
  border-radius: 10px;
  min-height: 400px;
  padding: 24px 20px;
  gap: 48px;
  color: $darker;
  position: relative;
  overflow: hidden;

  @include md {
    padding: 24px 48px;
  }

  @include lg-down {
    flex-direction: column;
  }

  &.no-taste-profile {
    min-height: 600px;
  }

  &.generating {
    background-color: black;
    color: white;
  }

  .main {
    @extend %flex-center-vertical;
    flex: 1;
    gap: 24px;

    .profile {
      @extend %flex-center-vertical;
      gap: 8px;

      .headline {
        font-size: 24px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 4px;
        line-height: 1;
        text-align: center;

        @include lg {
          font-size: 28px;
          margin-bottom: 8px;
        }

        @include xl {
          font-size: 32px;
          margin-bottom: 12px;
        }

        &.no-taste-profile,
        &.generating {
          font-size: 48px;

          @include md {
            font-size: 54px;
          }

          @include lg {
            font-size: 60px;
          }

          @include xl {
            font-size: 64px;
          }
        }

        i {
          font-family: $font-shop-italic;
          font-style: italic;
          font-weight: 500;
        }
      }
      .display {
        font-size: 14px;
        font-weight: 300;
        text-align: center;

        @include lg {
          font-size: 14px;
        }

        @include xl {
          font-size: 16px;
        }

        &.no-taste-profile {
          font-size: 16px;

          @include md {
            font-size: 18px;
          }

          @include lg {
            font-size: 18px;
          }

          @include xl {
            font-size: 20px;
          }
        }
      }
    }

    .actions {
      @extend %flex-row-middle;
      gap: 12px;

      @include sm-down {
        flex-direction: column;
        align-items: stretch;
        width: 100%;
      }

      .action {
        @extend %btn-darkest-shop;

        &.secondary {
          @extend %btn-outline-shop;
        }

        &.disabled {
          opacity: 0.4;
          cursor: default;

          &:hover {
            opacity: 0.4;
          }
        }
      }

      .cannot-create-message {
        font-size: 13px;
        color: $medium;
      }
    }
  }

  .product-column {
    min-width: 120px;
    min-height: 120px;
    position: relative;
    height: 100%;

    &.left {
      // transform: translateY(12px);
    }

    .product-column-images {
      @extend %flex-col;
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px 0;
      gap: 12px;

      @include lg-down {
        flex-direction: row;
        width: 100%;
      }

      .image-container {
        height: 112px;
        width: 112px;
        min-height: 112px;
        min-width: 112px;
        background-color: $offwhite;
        border-radius: 10px;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          background-color: white;
        }
      }
    }
  }
}

@import '../../styles/shared.scss';

.shop-controls-container {
  background-color: white;
  position: relative;

  @include xl {
    border-radius: 20px 20px 0 0;
  }

  .filters {
    @extend %flex-row;
    gap: 8px;
    justify-content: flex-start;
    overflow-x: auto;
    padding-right: 48px;
    padding: 20px 0 20px 20px;

    &.showing-all {
      flex-wrap: wrap;
    }

    .filter {
      @extend %flex-row-middle;
      padding: 8px 16px;
      border: $border-lighter;
      background-color: $white;
      font-size: 14px;
      border-radius: 24px;
      gap: 8px;
      color: $darker;
      cursor: pointer;
      transition: all 0.2s;
      white-space: nowrap;

      &.with-photo {
        padding: 4px 8px 4px 4px;
        gap: 6px;
      }

      &.empty {
        color: transparent;
        background-color: $prettymuchwhite;
        border-color: transparent;
        min-width: 120px;
      }

      &.inactive {
        &:hover {
          border-color: $light;
          color: $dark;
        }
      }

      &.adjusting {
        border-color: transparent !important;
        .label,
        .count,
        .remove-icn {
          opacity: 0.2;
        }
      }

      &.active {
        border: $border-darkest;
        color: $darkest;

        .label {
          font-weight: 500;
        }

        &:hover {
          border: $border-medium;
          .remove-icn {
            color: $darkest;
          }
        }
      }

      &.edit {
        border-color: transparent;
      }

      .image-container {
        @extend %flex-center;

        img.preview-image {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .label {
        font-size: 13px;
        transition: opacity 0.25s;

        .sublabel {
          color: $light;
        }
      }

      .remove-icn {
        @extend %flex-row-end;
        font-size: 12px;
        color: $medium;
        cursor: pointer;
        min-width: 10px;
        transition: opacity 0.25s;
      }

      .count {
        @extend %flex-row-end;
        font-size: 10px;
        font-weight: 400;
        color: $light;
        min-width: 10px;
        transition: opacity 0.25s;
      }

      .icon {
        font-size: 10px;
      }
    }

    .show-less-filter {
      border-color: white;
      .label {
        color: $light;
      }
    }
  }

  .show-all-btn-container {
    @extend %flex-center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), $white 64px);
    padding-left: 64px;
    pointer-events: none;

    @include md-down {
      display: none;
    }

    @include xl {
      border-top-right-radius: 20px;
    }

    .show-all-btn {
      @extend %btn-basic;
      font-size: 12px;
      color: $dark;
      font-weight: 500;
      padding: 8px 16px;
      pointer-events: auto;
    }
  }
}

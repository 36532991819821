@import '../../../styles/shared.scss';

.onboarding-wizard-outer-container {
  position: absolute;
  top: 100%;
  right: 50%;
  cursor: default;
  pointer-events: none;

  &.visible {
    pointer-events: auto;
  }

  .onboarding-wizard-cover {
    position: fixed;
    top: -100vh;
    left: -100vw;
    height: 300vh;
    width: 300vw;
    background-color: black;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.25s;

    &.visible {
      opacity: 0.4;
    }
  }
  .onboarding-wizard-inner-container {
    margin-top: 8px;
    z-index: 1000;
    background-color: white;
    color: $darkest;
    border-radius: 10px;
    border-top-right-radius: 0;
    position: relative;
    box-shadow: $shadow-lightest;
    padding: 40px;
    width: 540px;
    max-width: 90vw;
    transform-origin: top right;
    transition: transform 0.25s, opacity 0.25s;
    min-height: 240px;

    &:not(.visible) {
      transform: scale(0.8);
      opacity: 0;
      pointer-events: none;
      transition: transform 0.2s, opacity 0.2s;
    }

    &.visible {
      transform: scale(1);
      opacity: 1;
      pointer-events: auto;
      transition: transform 0.3s, opacity 0.3s;
    }

    .close-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      pointer-events: auto;
      font-size: 24px;
    }

    .wizard-header-container {
      margin-bottom: 24px;

      .wizard-header {
        font-size: 24px;
        font-family: $font-header;
        font-weight: 300;
        margin-bottom: 4px;
      }
      .wizard-subheader {
        font-size: 12px;
        color: $dark;
      }
    }

    .steps {
      @extend %flex-col;
      gap: 24px;

      .step {
        @extend %flex-row;
        gap: 12px;

        .status-container {
          @extend %flex-center;
          background-color: $lightest;
          color: $darkest;
          border-radius: 50%;
          min-width: 30px;
          min-height: 30px;
          width: 30px;
          height: 30px;

          svg {
            font-size: 14px;
            color: $light;
          }

          &.complete {
            background-color: $offwhite;

            svg {
              transform: scale(1);
              transition: transform 1s;
              transition-delay: 0.5s;
            }
          }

          &.animating-complete-start {
            svg {
              transform: scale(0.5);
              transition-delay: 0s;
            }
          }

          .number {
            font-weight: bold;
            font-size: 12px;
            color: $darkest;
          }
        }

        .main {
          @extend %flex-col-start;

          gap: 4px;

          .title {
            font-size: 24px;
            font-family: $font-header;
            font-weight: 300;
            position: relative;

            i {
              font-family: $font-shop-italic;
            }

            &::before {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              bottom: 50%;
              border-bottom: $border-lighter;
              transform: scale(0);
              transform-origin: left;
            }

            &.complete {
              color: $lighter;
              transition: color 1s;
              transition-delay: 0.5s;

              &::before {
                transition: transform 1s, opacity 1s;
                transform: scale(1);
                transition-delay: 0.5s;
              }
            }

            &.animating-complete-start {
              color: $dark;
              transition-delay: 0s;

              &::before {
                transform: scaleX(0);
                transition-delay: 0s;
              }
            }
          }

          .subtitle {
            font-size: 12px;
            color: $dark;

            &.complete {
              color: $light;
            }
          }

          .actions {
            @extend %flex-row-middle;
            margin-top: 12px;
            gap: 8px;

            &.complete {
              .action {
                opacity: 1;
                transition: opacity 0.5s;
                transition-delay: 0.5s;
              }
            }

            &.animating-complete-start {
              .action {
                opacity: 0.1;
                transition-delay: 0s;
              }
            }

            .action {
              @extend %btn-outline-shop;
              padding: 4px 12px;
              font-size: 10px;
              color: $darkest;

              &.disabled {
                display: none;
                .action {
                  opacity: 0.4;
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '../../styles/shared.scss';

.curator-group-outer-container {
  @extend %flex-col;
  gap: 24px;
  margin-top: 48px;
  padding: 0 20px;

  .curator-group-body {
    @extend %flex-row;
    gap: 24px;

    @include lg-down {
      flex-direction: column;
    }

    .curator-group-preview-card-container {
      flex: 1;

      .card-actions {
        @extend %flex-col;
        gap: 8px;
        margin-top: 12px;

        .card-action {
          @extend %flex-row-middle;
          gap: 8px;
          font-size: 11px;
          padding: 8px 12px 8px 16px !important;
          text-decoration: none;
          flex: 1;

          &.primary {
            @extend %btn-darkest-new;
            svg {
              color: white;
            }
          }

          &.secondary {
            @extend %btn-lightest-new;
          }

          svg {
            font-size: 12px;
          }
        }

        .card-select {
        }

        .card-specialty {
          margin-top: 8px;
          font-size: 10px;
          text-align: center;
          color: $medium;
        }

        .card-toggle {
          @extend %flex-row-middle;
          align-self: flex-end;
          gap: 8px;

          .label {
            font-size: 10px;
            color: $medium;
          }
        }
      }
    }

    .curators {
      flex: 3;

      &:not(.is-editing) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;

        @include sm {
          grid-template-columns: repeat(3, 1fr);
          gap: 12px;
        }
        @include lg {
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;
        }
        @include xl {
          grid-template-columns: repeat(5, 1fr);
          gap: 16px;
        }
      }

      &.is-editing {
        .sortable-items {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 8px;

          @include sm {
            grid-template-columns: repeat(3, 1fr);
            gap: 12px;
          }
          @include xl {
            grid-template-columns: repeat(4, 1fr);
            gap: 16px;
          }
        }
      }

      .add-new-card {
        @extend %flex-center-vertical;
        background-color: $prettymuchwhite;
        border: $border-white;
        border-radius: 10px;
        gap: 4px;
        min-height: 120px;
        cursor: pointer;
        transition: border 0.2s;

        &:hover {
          border: $border-lightest;

          svg {
            opacity: 1;
          }
          .add-label {
            opacity: 1;
          }
        }

        svg {
          font-size: 36px;
          opacity: 0.3;
          transition: opacity 0.2s;
        }

        .add-label {
          font-size: 14px;
          color: $medium;
          font-weight: 300;
          opacity: 0.5;
          transition: opacity 0.2s;
        }
      }
    }
  }
}

@import '../../../../styles/shared.scss';

.upgrade-shopper-account-panel-outer {
  .upgrade-shopper-account-panel-inner {
    @extend %flex-col;
    align-items: flex-start;
    font-size: 14px;
    color: $dark;

    .header {
      font-family: $font-header;
      font-size: 24px;
      font-weight: 300;
    }
    .subheader {
      margin-top: 12px;
      font-size: 14px;
      line-height: 1.4;
      color: $medium;
      max-width: 600px;
    }

    .upgrade-btn {
      @extend %btn-darkest-new;
      margin-top: 16px;
      padding: 12px 24px;
      font-size: 10px;
    }
  }
}

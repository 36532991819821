@import '../../styles/shared.scss';

.discover-curator-filters-container {
  .discover-filters {
    @extend %flex-center;
    flex-wrap: wrap;
    gap: 8px;

    @include md {
      gap: 12px;
    }

    .discover-filter {
      @extend %flex-row-middle;
      padding: 6px 12px;
      background-color: white;
      font-size: 12px;
      border-radius: 24px;
      gap: 8px;
      color: $darker;
      cursor: pointer;
      transition: all 0.2s;
      white-space: nowrap;

      @include md {
        padding: 8px 16px;
        font-size: 14px;
      }

      .remove-icon-container {
        @extend %flex-center;
        height: 100%;
        pointer-events: none;

        svg {
          padding-top: 2px;
        }
      }

      &:hover {
        opacity: 0.8;
      }

      &.active {
        border: $border-dark;
        color: $darkest;
      }

      &.another-active {
        opacity: 0.8;
      }
    }
  }
}

@import '../../../styles/shared.scss';

.collection-product.grid,
.collection-product.gallery {
  @extend %flex-col;
  flex: 1;
  position: relative;
  padding-top: 16px;
  background: white;
  transition: all 0.35s ease-in-out;

  &.grid {
    box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.3);

    border-radius: 8px;
  }

  @include lg {
    max-width: 340px;
    min-width: 240px;
  }

  &.is-editing {
    border-radius: 16px;
  }

  &.hidden {
    pointer-events: none;
    .collection-product-img-wrapper {
      position: relative;

      .hidden-cover {
        @extend %flex-center;
        width: 100%;
        height: 100%;
        top: 0;
        pointer-events: none;
        left: 0;
        position: absolute;
        background: rgba(255, 255, 255, 0.8);

        .badge {
          text-align: center;
          white-space: nowrap;
          background: white;
          padding: 4px 12px;
          border-radius: 4px;
          font-weight: bold;
          color: $darker;
          box-shadow: $shadow-dark;
        }
      }
    }
    .action-btn-container {
      pointer-events: auto;
    }
  }

  .product-heart-container {
    position: absolute;
    top: 8px;
    right: 8px;
    color: $medium;

    &.can-edit {
      right: 20px;
    }

    svg.disabled {
      color: $lightest;
      cursor: default;
    }
  }

  .collection-product-img-wrapper {
    width: 220px;
    height: 220px;
    min-height: 220px;
    border-radius: 4px;
    margin: 0 auto;

    @include sm-down {
      width: 40vw;
      height: 40vw;
      min-height: 40vw;
    }

    &.smooth {
      .product-image {
        @extend %animation-fade;
        animation-duration: 400ms;
      }
    }

    .product-image {
      align-self: center;
      width: 100%;
      height: 100%;
      transition: all 0.35s ease-in-out;
      cursor: pointer;
      object-fit: contain;

      &.empty {
        background: $prettymuchwhite;
        border-radius: 4px;
      }

      &:hover {
        transform: translateY(-2px);
      }
    }
  }

  .action-btn-container {
    @extend %flex-row;
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 18px;
    color: $medium;
    z-index: 10;
    font-weight: bold;

    @include sm-down {
      bottom: 38px;
    }

    > svg,
    > div {
      margin-left: 6px;
    }
  }

  .meta-container {
    @extend %flex-col-center;
    justify-content: space-between;
    z-index: 2;
    position: relative;
    transition: all 0.25s ease-in-out;
    flex: 1;

    a {
      text-decoration: none;
    }

    .header-container {
      @extend %flex-col;
      align-items: center;

      .header {
        font-size: 14px;
        line-height: 1.4;
        padding: 24px 16px 0;
        font-weight: normal;
        text-align: center;
        color: $darkest;
        margin: 0;
        font-family: $font-body;
        white-space: pre-line;

        @include md-down {
          padding: 16px 4px 0;
        }
      }

      .code-container {
        @extend %flex-row-middle;
        margin: 12px 0 4px;
        font-size: 12px;
        color: $darkest;
        font-weight: bold;

        svg {
          margin-right: 4px;
          font-size: 10px;
        }

        .code {
          .display {
            font-weight: bold;
          }
        }
      }

      @include sm {
        margin-bottom: 32px;
      }
    }

    .link-to-product-btn {
      @extend %btn-darkest-new;
      margin: 16px 0 0;
      padding: 10px 20px;
      font-size: 12px;
      z-index: 2;
      font-weight: bold;
      width: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      svg {
        margin-left: 4px;
        font-size: 11px;
      }

      @include sm {
        display: none;
      }
    }
  }

  .adjusting-product-loader {
    @extend %flex-center-vertical;
    height: calc(100% + 40px);
    width: 100%;
    margin-top: -40px;
    font-size: 14px;
    font-weight: bold;
    color: $darkest;
    transition: all 0.2s ease-in-out;
    position: absolute;
    z-index: 4;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.02);
  }

  .reorder-icn {
    position: absolute;
    top: 8px;
    right: 0px;
    color: $lighter;
  }

  &.grid {
    .reorder-icn {
      right: 8px;
    }
  }

  .show-description-icn {
    position: absolute;
    top: 8px;
    left: 8px;
    color: transparent;
    font-size: 20px;

    &.active {
      cursor: pointer;
      color: $medium;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .product-description-overlay {
    @extend %flex-center-vertical;
    height: calc(100% + 40px);
    width: 100%;
    margin-top: -40px;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.9);
    font-weight: bold;
    color: white;
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 4;
    opacity: 0;
    pointer-events: none;
    padding: 24px;

    &.long {
      overflow: auto;
      justify-content: flex-start;
      padding: 44px 20px 96px;
    }

    .close-icon {
      position: absolute;
      top: 32px;
      right: 12px;
      font-size: 24px;
      cursor: pointer;

      &:hover {
        color: $offwhite;
      }
    }

    &.visible {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

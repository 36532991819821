@import '../../../../styles/shared.scss';

.connect-socials-panel-outer {
  .connect-socials-panel-inner {
    .connect-social-btns {
      @extend %flex-row;
      align-items: flex-start;
      gap: 8px;

      @include sm-down {
        flex-direction: column;
      }

      .connect-social-btn {
        @extend %btn-basic;
        @extend %label;
        @extend %flex-row;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 10px 15px;
        border: $border-darkest;
        font-size: 12px;
        color: $darkest;
        gap: 8px;
        flex: 1;

        @include sm-down {
          width: 100%;
        }

        svg.social-icn {
          width: 24px;
          height: 24px;
          object-fit: cover;
          border-radius: 4px;

          path {
            fill: black;
          }
        }

        &.youtube {
          svg.social-icn {
            margin: -3px 0;
            width: 30px;
            height: 30px;
          }
        }

        &.disconnect {
          border: $border-primary !important;
          background: $primary !important;
          color: white !important;

          svg.social-icn {
            path {
              fill: white;
            }
          }
        }

        &.link {
          border: $border-offwhite;
        }

        &.warning {
          border: 2px solid $darkest;
        }

        &.connected {
          cursor: default;

          &:hover {
            opacity: 1;
          }
        }
      }

      .connect-social-btn-label {
        font-size: 10px;
        color: $medium;
      }
    }
  }
}

@import '../../../styles/shared.scss';
@import '../../variables.scss';

.more-options-menu-fade-container-new {
  font-family: $font-body;
  position: fixed;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
  z-index: 2;

  &.active {
    opacity: 1;
    pointer-events: auto;
    overflow: hidden;
  }
}

.more-options-menu-outer-container-new {
  @extend %flex-col;
  position: absolute;
  background: $white;
  color: $darkest;
  box-shadow: $shadow-dark;
  list-style: none;
  overflow: hidden;
  overflow: auto;
  top: calc(100% - 12px);
  right: 0;
  pointer-events: none;
  min-width: 280px;
  border-radius: 10px;
  display: none;
  z-index: 3;

  &.active {
    pointer-events: auto;
    display: flex;
  }

  @include md-down {
    position: fixed;
    top: 0;
    right: 0;
    border-radius: 0;
    width: 80vw;
    height: calc(100vh + 240px);
    max-width: 320px;
    padding: 48px 20px 240px;
    display: inherit;
    transition: transform 0.2s ease-in-out;
    transform: translateX(80vw);

    &.active {
      transform: translateX(0);
    }
  }

  @include md {
    right: 72px;
    padding: 24px 24px;

    &.scrolled {
      top: calc(100% - 6px);
    }
  }

  @include lg {
    right: 54px;
  }

  @include xl {
    right: 12px;
  }

  .user-info {
    @extend %flex-row-middle;
    padding-bottom: 16px;
    margin-bottom: 16px;
    gap: 8px;
    border-bottom: $border-offwhite;

    .image-container {
      width: 48px;
      height: 48px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .info-container {
      @extend %flex-col;
      gap: 2px;

      .name {
        font-size: 16px;
        color: $darkest;
      }

      .view-profile {
        @extend %flex-row-middle;
        font-size: 12px;
        color: $medium;
        gap: 4px;
        text-decoration: none;

        svg {
          font-size: 10px;
        }
      }
    }
  }

  .menu-groups {
    @extend %flex-col;
    gap: 18px;

    .menu-group {
      @extend %flex-col;
      gap: 8px;

      .group-title {
        font-size: 11px;
        font-weight: normal;
        color: $light;
        text-transform: uppercase;
        letter-spacing: 0.4px;
        padding: 0;
      }
      .group-items {
        @extend %flex-col;
        gap: 4px;

        .group-item {
          @extend %flex-row-middle-spread;
          @extend %btn-basic;
          font-size: 14px;
          color: $darker;
          font-weight: 400;
          text-decoration: none;

          &.has-count {
            color: $darkest;
            // background-color: $offwhite;
          }

          &.is-disabled {
            color: $lighter;
            cursor: default;

            &:hover {
              opacity: 1;
            }

            svg {
              color: $lighter;
            }
          }

          .new-tag {
            background-color: $primary;
            padding: 2px 4px;
            border-radius: 4px;
            color: white;
            font-size: 11px;
            font-weight: 500;
            text-align: center;
          }

          .count {
            background-color: $offwhite;
            padding: 2px 4px;
            border-radius: 10px;
            color: $darkest;
            font-size: 12px;
            font-weight: 500;
            min-width: 16px;
            text-align: center;
          }
        }
      }
    }

    .group-actions {
      @extend %flex-row;
      gap: 12px;
      margin-top: 24px;

      .group-action {
        @extend %btn-outline-shop;
        flex: 1;

        &.primary {
          @extend %btn-darkest-shop;
        }

        &.secondary {
          @extend %btn-outline-shop;
        }
      }
    }
  }

  .close-menu-icon {
    @extend %btn-basic;
    position: absolute;
    top: 0;
    right: 0;
    color: $darkest;
    font-size: 18px;
    padding: 12px;

    @include md-down {
      font-size: 24px;
      padding: 24px;
    }
  }
}

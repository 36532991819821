@import '../../styles/shared.scss';

.product-brand-panel-container {
  @extend %flex-row-middle;
  width: 100%;
  margin-top: 110px; // Match with ProductOtherProducts.scss
  margin-bottom: 64px;
  gap: 36px;

  .logo-container {
    @extend %flex-center;
    flex: 1;

    .logo {
      height: 180px;

      img {
        object-fit: contain;
        height: 100%;
        max-width: 400px;
      }
    }
  }

  .description-container {
    @extend %flex-col;
    justify-content: flex-start;
    flex: 1;
    gap: 12px;

    &.full-width {
      @extend %flex-center-vertical;
      margin: 0 auto;

      .description {
        max-width: 640px;
        text-align: center;
      }
      .shop-all-btn {
        align-self: center;
      }
    }

    .brand {
      font-family: $font-header;
      font-size: 40px;
      font-weight: 300;
    }

    .description {
      font-size: 16px;
      font-weight: 300;
      max-width: 480px;
    }

    .shop-all-btn {
      @extend %btn-darkest-shop;
      align-self: flex-start;
      margin-top: 24px;
    }
  }
}

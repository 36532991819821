@import '../../../styles/shared.scss';

.home-header-shop-outer-container {
  @extend %flex-col;
  justify-content: center;
  background-color: $offwhite;
  height: 60vh;
  max-height: 800px;
  padding-top: 140px;

  .home-header-shop-inner-container {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 10vh;

    // Match this to the header
    @include md-down {
      padding: 0 20px;
    }

    @include md {
      padding: 0 60px;
    }

    @include lg {
      padding: 0 40px;
    }

    @include xl {
      padding: 0 12px;
    }

    @include xxl {
      max-width: 1440px;
      padding: 0;
    }

    .main-container {
      @extend %flex-center-vertical;

      .title {
        font-family: $font-header;
        line-height: 1.3;
        color: $black;
        font-weight: 300;
        margin: 0;
        text-align: center;

        i {
          font-family: $font-shop-italic;
        }

        @include md-down {
          font-size: 40px;
        }

        @include md {
          font-size: 40px;
          background-color: transparent;
          max-width: 480px;
        }

        @include lg {
          font-size: 46px;
          max-width: 640px;
        }

        @include xl {
          font-size: 52px;
          max-width: 800px;
          width: 60%;
        }

        @include xxl {
          font-size: 54px;
        }
      }
      .subtitle {
        color: $black;
        font-weight: 300;
        margin: 0;
        text-align: center;

        i {
          font-family: $font-shop-italic;
        }

        @include md-down {
          // font-size: 40px;
        }

        @include md {
          // font-size: 40px;
          // background-color: transparent;
          // max-width: 480px;
        }

        @include lg {
          // font-size: 46px;
          // max-width: 640px;
        }

        @include xl {
          // font-size: 52px;
          // max-width: 800px;
          // width: 60%;
        }

        @include xxl {
          // font-size: 54px;
          font-size: 20px;
        }
      }
      .actions {
        @extend %flex-row;
        gap: 12px;
        margin-top: 44px;

        @include md-down {
          flex-direction: column;
          align-items: flex-start;
        }

        .action {
          &.primary {
            @extend %btn-darkest-shop;
            font-size: 14px;

            @include md {
              padding: 18px 36px;
              font-size: 16px;
            }

            @include xl {
              padding: 20px 44px;
              font-size: 16px;
            }
          }

          &.secondary {
            @extend %btn-outline-shop;
            color: $darkest;
            font-size: 14px;
            text-decoration: none;

            @include md {
              padding: 12px 24px;
              font-size: 16px;
            }

            @include xl {
              padding: 18px 36px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@import '../../styles/shared.scss';

.confirm-prompt-outer-container {
  font-family: $font-body-new;
  background-color: $prettymuchwhite;
  width: 60vw;
  max-height: 720px;
  max-width: 600px;
  border-radius: 20px;
  padding: 0;
  overflow: hidden;

  &.allow-overflow {
    overflow: visible;
  }

  @include md-down {
    width: 90vw;
  }

  .main-panel {
    padding: 30px;

    @include xl {
      padding: 44px 40px;
    }
  }

  &.has-secondary {
    @extend %flex-row;
    width: 80vw;
    max-height: 600px;
    max-width: 960px;

    .main-panel {
      flex: 2;
    }
    .secondary-panel {
      flex: 1;
      padding: 24px;
      max-width: 400px;
      background: $offwhite;

      @include lg-down {
        display: none;
      }
    }
  }

  .header {
    font-family: $font-header;
    margin: 0 0 8px;
    line-height: 1;
    color: $darkest;
  }
  .subheader {
    font-size: 13px;
    margin: 0 0 8px;
    color: $medium;
  }

  .custom-input-field-container {
    padding-bottom: 16px;

    .custom-input-field-display {
      font-weight: 500;
      font-size: 13px;
      color: $darker;
    }

    .custom-input-field-subdisplay {
      font-size: 10px;
      color: $dark;

      a,
      .clickable {
        color: $dark;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .DateRangePickerInput {
      background-color: transparent;
      .DateInput {
        background-color: transparent;
      }
    }

    textarea,
    input {
      margin-top: 8px;

      @include md {
        font-size: 13px;
      }
    }
  }

  textarea,
  input {
    @extend %input-shared;
    margin-top: 12px;
    width: 100%;

    @include md {
      font-size: 13px;
    }
  }

  .switch-wrapper {
    @extend %flex-row-middle;
    gap: 12px;

    .label {
      font-size: 13px;
      font-weight: 500;
    }
  }

  .select-wrapper {
    @extend %flex-col;
    margin-top: 8px;

    .label {
      padding-right: 8px;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .cancel-btn {
    background: white !important;
    color: $dark !important;
    font-weight: bold !important;
  }
  .submit-btn {
    position: relative;
    // background: white;
    // color: $dark;
    // font-weight: bold;

    &.submitting {
      color: transparent !important;
    }

    .loader {
      position: absolute;
      left: 0;
    }
  }
}

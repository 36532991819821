@import '../../styles/shared.scss';

.product-other-products-container {
  margin: 110px 0;

  &.no-margin-top {
    margin-top: 0;
  }

  .product-other-products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;

    @include md {
      grid-template-columns: repeat(3, 1fr);
    }
    @include xl {
      grid-template-columns: repeat(4, 1fr);
    }

    .other-product-outer {
      background-color: white;
      padding: 16px 8px;
      position: relative;

      @include sm {
        padding: 24px;
      }

      .other-product {
        @extend %flex-col-center;
        gap: 8px;
        border: $border-transparent;
        text-decoration: none;
        color: $darkest;

        @include md {
          gap: 16px;
        }

        &.loading {
          background-color: $prettymuchwhite;
        }

        .this-product-label {
          position: absolute;
          top: 16px;
          left: 16px;
          font-size: 12px;
          color: $dark;
        }

        &.is-this-product {
          border: $border-dark;
        }
        .image-container {
          width: 90%;
          aspect-ratio: 1;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 8px;
            aspect-ratio: 1;
            transition: transform 0.3s;

            &:hover {
              transform: scale(1.05);
            }
          }
        }

        .data {
          @extend %flex-col;
          align-self: stretch;
          gap: 4px;

          .brand {
            font-size: 12px;
            color: $light;
            font-weight: 500;
            text-decoration: none;
            white-space: nowrap;
            text-align: center;

            @include sm {
              font-size: 14px;
            }

            &.loading {
              background-color: $offwhite;
              border-radius: 4px;
              min-height: 16px;
              width: 80px;
              margin: 0 auto;
            }
          }

          .title {
            font-size: 15px;
            font-weight: 500;
            color: $black;
            text-decoration: none;
            line-height: 1.2;
            text-align: center;

            @include sm {
              font-size: 17px;
            }

            &.loading {
              background-color: $offwhite;
              border-radius: 4px;
              min-height: 20px;
              width: 200px;
              margin: 0 auto;
            }
          }

          .metadata {
            font-size: 11px;
            color: $medium;
            font-weight: 500;
            white-space: nowrap;
            text-align: center;

            @include sm {
              font-size: 12px;
            }

            &.loading {
            }
          }
        }
      }

      .favorite-container {
        position: absolute;
        top: 4px;
        right: 4px;
        padding: 10px;
        z-index: 1;
      }
    }
  }
}

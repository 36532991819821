@import '../../../styles/shared.scss';

.shop-header-search-result {
  @extend %flex-row-middle-spread;
  border-radius: 4px;
  padding: 6px 8px;
  gap: 12px;
  cursor: pointer;

  &.empty {
    pointer-events: none;

    .main-result {
      @extend %flex-row;
      justify-content: start;
      flex: 1;

      .image-container .empty {
        color: transparent;
        background-color: $offwhite;
      }

      .term {
        flex: 1;
        color: transparent;
        background-color: $offwhite;
        border-radius: 4px;
      }
    }
  }

  &:hover {
    background-color: $lightest;
  }

  &.auto {
    background-color: $lightest;
  }

  .main-result {
    @extend %flex-row-middle;
    gap: 8px;

    .image-container {
      @extend %flex-col;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      overflow: hidden;

      &.Brand {
        img {
          object-fit: contain;
        }
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }

      &.Query {
        @extend %flex-center;
        background-color: white;
        font-size: 12px;
      }

      .empty {
        @extend %flex-center;
        width: 100%;
        height: 100%;
        background-color: $darker;
        font-family: $font-header;
        color: $white;
        font-weight: bold;
        font-size: 14px;
        padding-top: 1px;
        line-height: 1;
      }
    }

    .term {
      @extend %animation-fade;
      font-weight: 500;
      font-size: 14px;
      color: $light;

      .highlighted {
        font-weight: bold;
        color: $black;
      }
    }
  }

  .actions {
    @extend %flex-row-middle;
    gap: 8px;

    .action {
      &.enter {
        font-size: 8px;
        color: $light;
        font-weight: 500;
        white-space: nowrap;
      }

      &.favorite {
        font-size: 14px;
        color: $dark;
        opacity: 1;
        transition: opacity 0.25s;

        &.editing {
          opacity: 0.2;
        }
      }
    }
  }
}

@import '../../../styles/shared.scss';

.shop-results-show-more-options-container {
  @extend %flex-col-center;
  padding: 60px 24px 0;
  gap: 24px;

  .show-more-header {
    font-family: $font-header;
    color: $darker;
    font-size: 24px;
    font-weight: 300;
  }

  .refinement-pills {
    @extend %flex-center;
    flex-wrap: wrap;
    gap: 12px;

    .refinement-pill {
      @extend %flex-center;
      padding: 12px 20px;
      border-radius: 24px;
      gap: 12px;
      background-color: white;
      border: $border-transparent;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        border: $border-dark;
      }

      .refinement-label {
        color: $dark;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

@import '../../styles/shared.scss';

.taste-outer-container {
  padding-top: $shoppingNavHeight;
  min-height: 100vh;
  background-color: $offwhite;
  font-family: $font-body-new;
  padding-bottom: 25vh;

  .taste-inner-container {
    width: calc(100vw - 48px);
    margin: 0 auto;
    max-width: 1240px;
    padding-top: $shopPaddingTopSM;

    @include md {
      padding-top: $shopPaddingTopMD;
    }
    @include lg {
      padding-top: $shopPaddingTopLG;
    }
    @include xl {
      padding-top: $shopPaddingTopXL;
    }
    @include xxl {
      padding-top: $shopPaddingTopXXL;
    }
  }
}

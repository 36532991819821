@import '../../../styles/shared.scss';

.shop-header-search-results-outer-container {
  background-color: white;
  padding: 16px 8px;
  border-radius: 10px;
  box-shadow: $shadow-dark;
  max-height: 600px;
  overflow-y: auto;
  width: calc(100vw - 40px);

  @include sm {
    min-width: 440px;
    max-width: 440px;
  }

  .shop-header-search-results-inner-container {
    @extend %flex-col;
    .result-types {
      @extend %flex-row-middle;
      align-self: center;
      margin-bottom: 12px;
      gap: 2px;

      .result-type {
        flex: 1;
        padding: 4px 6px;
        font-size: 11px;
        cursor: pointer;
        border-radius: 4px;
        font-weight: 500;
        color: $medium;
        white-space: nowrap;
        transition: background-color 0.15s, color 0.15s;

        @include sm {
          padding: 4px 10px;
        }

        &:hover {
          &:not(.active) {
            background-color: $offwhite;
            color: $dark;
          }
        }

        &.active {
          background-color: $darkest;
          color: white;
        }
      }
    }
    .search-results {
      @extend %flex-col;
      transition: opacity 0.25s;

      &.searching {
        opacity: 0.2;
      }
    }

    .loading-results {
      @extend %flex-col-center;
      padding: 24px;

      .header {
        font-size: 20px;
        font-weight: 500;
        color: $darkest;
      }
    }

    .no-term-search-panel {
      @extend %flex-col-center;
      padding: 24px;

      .header {
        font-size: 20px;
        font-weight: 500;
        color: $darkest;
      }
      .explainer {
        font-size: 14px;
        line-height: 1.4;
        color: $medium;
        margin-top: 8px;
        text-align: center;

        a {
          color: $darkest;
          text-decoration: underline;
          font-weight: bold;
        }
      }
    }

    .search-results-footer {
      font-size: 11px;
      color: $medium;
      padding: 12px;

      .clickable {
        cursor: pointer;
        color: $darkest;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
}

@import '../../styles/shared.scss';

.product-results-container {
  @extend %flex-col;

  .product-filtering-container {
    @extend %flex-col;
    width: 100%;
    overflow: auto;
    padding-top: 4px;

    @include md {
      overflow: hidden;

      &.long {
        overflow: auto;
      }
    }

    .product-filter-row {
      margin: 4px 12px;

      .header {
        font-size: 13px;
        margin-bottom: 6px;
        color: $darkest;
      }

      .product-filters {
        @extend %flex-row-middle;
        flex-wrap: nowrap;
        padding: 0 0 8px;
        overflow: scroll;

        .product-filter {
          @extend %flex-row-middle-spread;
          margin-right: 12px;
          padding: 2px 8px;
          border-radius: 24px;
          white-space: nowrap;
          border: $border-lighter;
          font-size: 14px;
          color: $dark;
          cursor: pointer;
          transition: all 0.1s ease-in-out;

          &.inactive {
            opacity: 0.5;
          }

          &.selected {
            color: $white;
            border: $border-darkest;
            background: $dark;
            font-weight: bold;
          }

          .count {
            margin-left: 4px;
            font-size: 10px;
            color: $light;
            transform: translateY(1px);
          }
        }
      }
    }
  }

  .unlikely-match-results-alert {
    font-size: 16px;
    color: $dark;
    text-align: center;
    border: $border-offwhite;
    margin: 12px;
    padding: 24px;

    span {
      color: $link-blue;
      cursor: pointer;
    }

    @include md {
      padding: 24px;
    }
  }

  .empty-search-results-message {
    padding: 48px 32px 24px;
    display: inline;
    font-size: 16px;
    color: $dark;
    text-align: center;

    span {
      @extend %link;
      text-decoration: underline;
    }

    a {
      @extend %link;
    }

    @include md {
      padding: 24px 20%;
    }
  }

  .debug-section {
    text-align: right;
    font-size: 10px;
    margin-bottom: 12px;
    color: $light;
    text-transform: uppercase;
  }

  .product-results {
    @extend %flex-row;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;

    .product-result {
      @extend %flex-row-middle;
      transition: all 0.3s ease-in-out;
      align-self: stretch;
      background: white;
      border-radius: 4px;
      pointer-events: auto;
      width: 100%;
      border: $border-offwhite;
      position: relative;
      margin: 12px;
      padding: 8px 12px;

      @include sm {
        width: calc(50% - 24px);
      }

      .image,
      img {
        @extend %animation-fade;
        min-width: 120px;
        width: 120px;
        height: 120px;
        min-height: 120px;
        border-radius: 8px;
        object-fit: contain;

        &.empty {
          background: $offwhite;
        }
      }

      .meta-container {
        width: 100%;
        padding: 12px;

        .header {
          color: transparent;
          background: $offwhite;
        }

        .title {
          font-size: 12px;
          font-weight: bold;
          color: $darkest;
          width: 100%;

          .highlighted {
            font-weight: bold;
            background: $tertiary;
          }
        }

        .subtitle {
          font-size: 12px;
          color: $light;
          text-transform: uppercase;

          .highlighted {
            font-weight: bold;
            background: $tertiary;
          }
        }

        .description {
          font-size: 12px;
          color: $medium;
          margin-top: 12px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-height: 13px;
        }

        .variation-count {
          font-size: 12px;
          color: $light;
          margin-top: 2px;
        }

        .hidden-from-public-catalog {
          @extend %label;
          font-size: 10px;
          color: $light;
          margin-top: 2px;
        }

        .details {
          @extend %flex-col;
          padding-top: 16px;

          .sold-at,
          .commission,
          .code,
          .price,
          .partner {
            font-size: 12px;
            font-weight: bold;
            color: $dark;

            svg {
              font-size: 10px;
              margin-left: 4px;
              cursor: pointer;
              color: $medium;
            }

            a {
              color: $dark;
              text-decoration: none;
            }
          }

          .commission {
            @extend %flex-row-middle;
            align-self: flex-start;

            .merchant-payout-icon {
              @extend %flex-row-middle;
              margin-left: 4px;

              img {
                display: flex;
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
              }
            }
          }

          .partner {
            @extend %flex-row-middle;
            gap: 4px;
            font-weight: normal;
            color: $primary;

            svg {
              color: $primary;
              margin-left: 0px;
            }
          }
        }
      }
    }

    .result-class-seperator {
      width: 100%;
      padding: 32px 12px 16px;

      .header {
        font-size: 18px;
        font-weight: bold;
        color: $darkest;
      }

      .subheader {
        font-size: 14px;
        font-weight: normal;
        color: $dark;
      }
    }
  }

  .high-rate-merchants {
    @extend %flex-row;
    flex-wrap: wrap;
    width: 100%;

    @include sm {
      padding: 0;
    }

    .high-rate-merchant-container {
      @extend %flex-row;
      width: 100%;
      min-height: 72px;
      padding: 6px 6px;
      position: relative;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      @include sm {
        width: 50%;
        min-height: 72px;
      }

      @include xl {
        min-height: 80px;
      }

      .high-rate-merchant {
        @extend %flex-row-middle-spread;
        border: $border-dark;
        border-radius: 10px;
        background: $white;
        text-decoration: none;
        padding: 4px 16px;
        color: $darker;
        flex: 1;

        .name {
          font-size: 18px;
        }

        .domain {
          font-size: 11px;
          color: $dark;
        }

        .partner {
          @extend %flex-row-middle;
          gap: 4px;
          color: $primary;
          font-size: 11px;
          font-weight: bold;
          margin-top: 4px;

          svg {
            color: $primary;
            margin-left: 0px;
          }
        }

        .rate {
          font-size: 28px;
          font-weight: bold;
          color: $darker;
        }
      }
    }
  }

  .loading-new-page-loader {
    margin: 12px 0;
  }
}

@import '../../styles/shared.scss';

.modal-outer-container.instagram-csv-creation-modal {
  .modal-container {
    max-width: 720px;

    .modal-inner-container {
      .modal-content {
        @extend %flex-col;
        gap: 40px;
        padding: 40px;

        .instagram-csv-header-section {
          @extend %flex-col;
          gap: 4px;

          .instagram-csv-header {
            font-size: 24px;
            font-weight: 600;
            color: $darkest;
          }
          .instagram-csv-subheader {
            font-size: 16px;
            font-weight: 400;
            color: $medium;
            line-height: 1.4;

            a {
              color: $dark;
              font-weight: 500;
            }
          }
        }

        .steps {
          @extend %flex-col;
          gap: 20px;

          .step {
            @extend %flex-row-middle;
            gap: 8px;

            .number {
              @extend %flex-center;
              width: 36px;
              height: 36px;
              min-width: 36px;
              min-height: 36px;
              border-radius: 50%;
              color: white;
              background-color: $darkest;
              font-size: 18px;
              line-height: 18px;
              font-weight: bold;
            }

            .description {
              font-weight: 16px;
              color: $dark;
              font-weight: 300;

              a {
                color: $primary;
                font-weight: 500;
              }

              .emphasize {
                font-weight: bold;
                color: $darkest;
              }

              .fallback {
                font-size: 11px;
                color: $medium;
                margin-top: 4px;

                a {
                  color: $dark;
                  font-weight: 500;
                }
              }
            }
          }
        }

        .upload-btn {
          @extend %flex-center;
          border: $border-light;
          border-radius: 10px;
          color: $medium;
          font-size: 14px;
          height: 80px;
          min-height: 80px;
          cursor: pointer;

          &.uploading {
            background-color: $lightest;
            color: $darkest;
            border: none;
            cursor: not-allowed;
          }

          &.uploaded {
            border-color: $darkest;
            color: $darkest;
            background-color: $lightest;
            font-weight: 500;
          }
        }

        .create-btn {
          @extend %btn-darkest-shop;
          margin-bottom: 48px;

          &.disabled {
            opacity: 0.3;
            cursor: default;
          }
        }
      }
    }
  }
}

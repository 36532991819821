@import '../../styles/shared.scss';

.auth-modal-outer-container {
  @extend %flex-center;
  position: fixed;
  font-family: $font-body-new;
  height: 100%;
  width: 100%;
  z-index: 1000;
  padding: 0 20px;

  .auth-modal-fade-container {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }

  .auth-modal-inner-container {
    position: relative;
    padding: 24px;
    overflow: auto;
    border-radius: 12px;
    z-index: 1;
    background: white;
    min-width: 90vw;
    max-height: 90vh;

    &.full-width {
      padding: 0;
    }

    @include md {
      border-radius: 10px;
      height: auto;
      padding: 48px;
      max-width: 720px;
      min-width: 540px;
      min-height: 240px;

      &.full-width {
        padding: 0;
        max-width: 840px;
      }
    }

    .close-icn {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 24px;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }

    .auth-section {
      @extend %flex-col-stretch;

      .auth-header {
        @extend %header-fancy-md;
        color: $darkest;
        font-weight: 300;
      }

      .auth-subheader {
        @extend %flex-row;
        font-size: 14px;
        color: $medium;
        margin-top: 4px;
        line-height: 1.4;

        .option {
          margin-left: 4px;
          cursor: pointer;
          color: $lighter;

          &.selected {
            color: $darker;
            font-weight: bold;
          }
        }
      }

      form {
        @extend %flex-col;

        .search-input-container {
          @extend %flex-col;
          margin-top: 16px;

          input {
            margin-top: 0;
          }
        }

        input {
          @extend %input-shared;
          font-family: $font-body-new;
          margin-top: 16px;
        }

        textarea {
          @extend %input-shared;
          font-family: $font-body-new;
          margin-top: 16px;
        }

        .how-did-you-hear-select {
          margin-top: 16px;
        }
      }

      .login-btn-container {
        position: relative;
        margin-top: 16px;

        .login-btn {
          @extend %btn-darkest;
          width: 100%;
          padding: 12px 0;
          font-size: 16px;

          &.loading {
            color: transparent;
          }
        }

        .loader {
          position: absolute;
          top: 4px;
          background: transparent;
        }
      }

      .forgot-password,
      .back-to-login {
        text-decoration: underline;
        color: $medium;
        text-transform: uppercase;
        font-size: 16px;
        margin-top: 8px;
        cursor: pointer;
      }

      .secondary-actions {
        max-width: 440px;

        .action-header {
          font-size: 16px;
          font-weight: bold;
          color: $darkest;
          margin-top: 24px;
        }

        .action-subheader {
          font-size: 14px;
          color: $darker;
          margin-top: 2px;

          .action {
            @extend %btn-basic;
            font-weight: bold;
            text-decoration: underline;
          }
        }

        .action-btn {
          @extend %btn-darkest-new;
          padding: 12px 32px;
          margin-top: 12px;
        }
      }
    }
  }
}

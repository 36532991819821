@import '../../styles/shared.scss';

.shop-main-home-header {
  @extend %flex-col-center;
  padding: 0 24px 40px;
  gap: 8px;

  h1 {
    font-size: 44px;
    line-height: 1;
  }

  h2 {
    font-size: 16px;
    line-height: 1.4;
  }

  @include sm {
    padding-bottom: 40px;
    h1 {
      font-size: 40px;
    }

    h2 {
      font-size: 16px;
    }
  }
  @include md {
    padding-bottom: 48px;
    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 16px;
    }
  }
  @include lg {
    padding-bottom: 56px;
    h1 {
      font-size: 56px;
    }

    h2 {
      font-size: 18px;
    }
  }
  @include xl {
    padding-bottom: 64px;
    h1 {
      font-size: 64px;
    }

    h2 {
      font-size: 20px;
    }
  }

  h1,
  h2 {
    margin: 0;
    text-align: center;
  }

  h1 {
    color: $darkest;
    font-weight: 400;

    i {
      font-family: $font-shop-italic;
      font-style: italic;
      font-weight: 500;
    }
  }

  h2 {
    font-weight: 300;
    color: $darkest;
  }

  .mock-search-container {
    margin: 24px 48px 0;

    @include md {
      margin-top: 30px;
    }
    @include xxl {
      margin-top: 36px;
    }

    .mock-search-input {
      @extend %flex-row-middle;
      gap: 12px;
      background-color: white;
      border-radius: 24px;
      padding: 12px 24px;
      cursor: pointer;
      width: 80vw;
      max-width: 480px;
      overflow: hidden;

      svg {
        font-size: 14px;
      }

      .placeholder {
        color: $light;
        font-size: 16px;
        white-space: nowrap;
        transition: opacity 0.3s ease-in-out;

        &.transitioning {
          opacity: 0;
        }
      }
    }
  }
}

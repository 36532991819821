@import '../../../styles/shared.scss';

.shop-header-empty-search-results-panel {
  @extend %flex-col-center;
  padding: 12px 24px 24px;
  color: $darkest;

  .header {
    font-size: 20px;
    font-weight: 500;
    color: $darkest;
    text-align: center;
  }
  .explainer-container {
    .explainer {
      font-size: 14px;
      line-height: 1.4;
      color: $medium;
      margin-top: 8px;
      text-align: center;

      a {
        color: $darkest;
        text-decoration: underline;
        font-weight: bold;
      }
    }

    .example-search-terms {
      @extend %flex-col-center;
      margin-top: 24px;
      gap: 4px;

      .example-search-term {
        @extend %btn-basic;
        color: $light;
        font-size: 12px;
      }
    }
  }
}

@import '../../styles/shared.scss';

.artist-modal-sidebar-container {
  @extend %flex-col;
  padding: 48px 0;
  position: relative;
  overflow-y: auto;
  border-right: $border-offwhite;

  @include md-down {
    overflow-y: inherit;
    padding: 24px 0 0;
    .image-container {
      width: 240px !important;
      min-width: 240px !important;
      width: 240px;
    }
  }

  .back-button {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 20px;
    cursor: pointer;
    color: $darkest;
    z-index: 2;

    @include md {
      top: 12px;
      left: 12px;
      font-size: 24px;
    }
  }

  .image-container {
    @extend %flex-col;
    align-self: center;
    min-width: 180px;
    width: 180px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;

    &.fetching-new {
      .loader {
        opacity: 1;
      }
    }

    .loader {
      @extend %flex-center;
      position: absolute;
      top: 0;
      height: 100%;
      opacity: 0;
      background: rgba(0, 0, 0, 0.6);
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;
    }

    img {
      width: 100%;
      object-fit: cover;
    }

    .image.empty {
      @extend %flex-center;
      height: 100%;
      width: 100%;
      background: $darkest;
      font-family: $font-header;
      font-size: 48px;
      color: white;
      flex: 1;
    }

    .social-icons-fade {
      transition: opacity 0.3s;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 50%;
      width: 100%;
      background-image: linear-gradient(0deg, #000, transparent, transparent);
      opacity: 0.25;
    }

    .social-links {
      @extend %flex-row;
      padding: 6px 4px 0;
      border-radius: 4px;
      align-items: flex-start;
      justify-content: flex-end;
      position: absolute;
      z-index: 2;
      bottom: 0;
      right: 0;

      a.social-link {
        margin: 0 3px;

        svg {
          font-size: 17px;
          margin-bottom: 3px;
          color: $white;
        }

        img {
          color: $dark;
          height: 20px;
          object-fit: cover;
          border-radius: 3px;
          overflow: hidden;
        }

        svg,
        img {
          cursor: pointer;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    .select-list-btn {
      @extend %btn-basic;
      padding: 6px 4px 0;
      border-radius: 4px;
      align-items: flex-start;
      justify-content: flex-end;
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      color: $offwhite;

      svg {
        font-size: 18px;
      }

      &.active {
        color: $white;
      }
    }
  }
  .data-container {
    @extend %flex-col;
    justify-content: space-between;
    padding: 20px 20px 12px;
    width: 100%;
    color: $darker;
    background: $white;
    z-index: 2;

    .name {
      font-size: 23px;
      font-family: $font-header;
      font-weight: bold;
      margin-bottom: 12px;
      text-align: center;
    }

    .description {
      font-size: 12px;
      line-height: 18px;
      color: $dark;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      position: relative;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      &.full {
        -webkit-line-clamp: 40;
      }
    }

    .links {
      margin-top: 12px;
      @extend %flex-col;
      align-items: flex-start;

      .link {
        @extend %label;
        color: $dark;
        font-weight: bold;
        font-size: 12px;
        padding-top: 4px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          color: $darker;

          svg {
            // transform: translateY(-1px);
            transform: scale(1.15);
          }
        }

        svg {
          transition: transform 0.2s ease-in-out;
          font-size: 14px;
          margin-right: 4px;
          width: 18px;
        }
      }
    }

    .tags {
      @extend %flex-row-middle;
      flex-wrap: wrap;
      margin-top: 8px;

      .tag {
        font-size: 9px;
        padding: 2px 8px;
        margin: 0 3px 3px 0;
        border-radius: 12px;
        text-transform: uppercase;
        border: $border-light;
        color: $darkest;
      }
    }

    .section {
      margin-top: 24px;

      .label {
        @extend %label-xxs;
        color: $medium;
        margin-bottom: 4px;
      }
    }

    .social-counts {
      @extend %flex-col;
      gap: 2px;

      .social-count-container {
        display: grid;
        grid-template-columns: 1fr min-content;

        .social-count {
          font-size: 12px;
          color: $darker;

          .count,
          .handle {
            font-weight: bold;
            color: $darkest;
          }

          .count {
            text-transform: uppercase;
          }

          .handle {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .network {
            white-space: nowrap;
          }

          svg {
            margin-left: 4px;
            font-size: 7px;
            margin-bottom: 1px;
          }
        }

        .connection-label {
          @extend %label;
          font-size: 8px;
          color: $light;
          background-color: $white;
          padding: 2px 4px;
          border-radius: 4px;
          font-weight: bold;

          &.connected {
            color: $darkest;
            background-color: $offwhite;
          }
        }
      }
    }

    .address-section {
      .address {
        font-size: 12px;
        color: $darkest;
      }
    }
  }
}

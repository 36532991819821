@import '../../styles/shared.scss';

.curator-group-header-container {
  @extend %flex-row-spread;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex-wrap: wrap;

  .go-back {
    @extend %btn-basic;
    position: absolute;
    bottom: 100%;
    left: 0;
    padding-bottom: 16px;
    font-size: 14px;
    color: $dark;

    svg {
      margin-right: 8px;
      font-size: 12px;
    }
  }

  .curator-group-header {
    flex: 1;
    min-width: 240px;

    .header {
      font-size: 30px;
      font-weight: 300;
      color: $darkest;
      font-family: $font-header;

      .emphasize {
        font-family: $font-shop-italic;
        font-style: italic;
        letter-spacing: -2%;
        font-size: 30px;
      }

      input {
        @extend %input-inline;
        font-size: 24px;
        color: $darkest;
        font-family: $font-shop-italic;
        font-style: italic;
        letter-spacing: -2%;
        font-size: 30px;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        width: 100%;
      }
    }

    .subheader {
      font-size: 14px;
      color: $dark;
    }
  }

  .actions {
    @extend %flex-row-end;
    gap: 8px;
    flex-wrap: wrap;

    @include md-down {
      justify-content: flex-start;
      gap: 4px;
    }

    .action {
      @extend %flex-row-middle;
      gap: 8px;
      font-size: 11px;
      padding: 8px 12px 8px 16px !important;
      position: relative;

      @include md-down {
        padding: 8px 8px 8px 8px !important;
      }

      &.taking-action {
        color: transparent;

        .loader {
          @extend %flex-center;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }

      &.primary {
        @extend %btn-darkest-new;

        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }

        svg {
          color: white;
        }
      }

      &.secondary {
        @extend %btn-lightest-new;
      }

      svg {
        font-size: 12px;
      }
    }
  }
}

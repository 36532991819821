@import '../../../styles/shared.scss';

.product-heart-overlay-outer-container {
  position: relative;
  font-family: $font-body-new;

  .product-heart-overlay-fade {
    background: black;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    overflow: hidden;
    will-change: opacity;

    &.visible {
      opacity: 0.4;
    }
  }

  .collections-container {
    position: fixed;
    background: white;
    border-radius: 16px;
    max-height: 400px;
    min-height: 240px;
    opacity: 1;
    z-index: 102;
    transform-origin: top right;
    overflow: auto;
    box-shadow: $shadow-dark;

    .header-outer-container {
      @extend %flex-row-middle-spread;
      padding: 24px 24px 12px;
      gap: 12px;

      .input-container {
        flex: 1;
        input {
          @extend %input-shared;
          width: 100%;
        }
      }

      .header-container {
        .header {
          font-size: 16px;
          font-weight: 500;
          color: $darkest;
        }

        .subheader {
          font-size: 12px;
          font-weight: 300;
          color: $medium;
        }
      }
      .actions {
        @extend %flex-row-middle;
        gap: 4px;

        .action {
          @extend %flex-row-middle;
          @extend %btn-offwhite-shop;
          height: 28px;
          padding: 0 8px;
          gap: 6px;
          font-size: 12px;

          &.search.done {
            height: 40px;
            background-color: $darkest;
            color: white;
          }

          svg {
            font-size: 10px;
          }
        }
      }
    }

    .collections {
      @extend %flex-col;
      transition: opacity 0.2s;

      &.searching {
        opacity: 0.2;
      }

      .no-collections {
        @extend %flex-center;
        height: 100%;
        padding: 36px 24px;
        margin: 12px 24px;
        background-color: $offwhite;
        border-radius: 10px;
        font-size: 12px;
        color: $medium;
      }

      .collection {
        @extend %flex-row-middle-spread;
        padding: 4px 12px;
        margin: 4px 12px;
        border-radius: 4px;
        gap: 8px;

        &.active {
          background-color: $offwhite;
        }

        .main {
          @extend %flex-row-middle;
          flex: 1;
          gap: 8px;

          .image-container {
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            border-radius: 10px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .no-image {
              @extend %flex-row-middle;
              @extend %flex-center;
              width: 100%;
              height: 100%;
              background: $offwhite;
              color: $dark;
              font-size: 12px;
            }
          }

          .data {
            .name {
              font-size: 12px;
              color: $darkest;
              text-decoration: none;
              line-height: 1;

              &:hover {
                text-decoration: underline;
              }
            }
            .datetime {
              font-size: 10px;
              font-weight: 300;
              color: $medium;
            }
          }
        }

        .actions {
          .action {
            @extend %flex-col;
            @extend %flex-center;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            border: $border-lighter;
            cursor: pointer;
            transition: opacity 0.2s;
            color: $light;
            gap: 8px;
            font-size: 12px;

            &.active {
              border: $border-dark;
              color: $dark;
            }

            &.adding,
            &.removing {
              border-color: transparent;
            }

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

@import '../../styles/shared.scss';

.product-error-panel-container {
  @extend %flex-center-vertical;
  background-color: white;
  min-height: 60vh;
  gap: 24px;

  .header-container {
    @extend %flex-center-vertical;

    .header {
      font-family: $font-header;
      font-size: 36px;
      font-weight: 300;
      color: $darkest;
    }
    .subheader {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      color: $medium;
    }
  }

  .retry-btn {
    @extend %btn-darkest-new;
    padding: 12px 24px;
    font-size: 12px;
  }
}

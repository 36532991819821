@import '../../../styles/shared.scss';

.shop-page-header-new-container {
  @extend %flex-col;
  overflow: hidden;
  position: relative;

  .main-panel {
    @extend %flex-col-center;
    flex: 3;
    z-index: 1;
    padding-bottom: 24px; // SPACING

    @include sm {
      padding-bottom: 24px; // SPACING
    }
    @include md {
      padding-bottom: 24px; // SPACING
    }
    @include lg {
      padding-bottom: 30px; // SPACING
    }
    @include xl {
      padding-bottom: 30px; // SPACING
    }
    @include xxl {
      padding-bottom: 36px; // SPACING
    }

    .header-section {
      @extend %flex-col-center;
      position: relative;
      margin: 0 auto;
      align-self: flex-center;

      h1,
      h2 {
        margin: 0;
        font-weight: inherit;
      }

      .label,
      .name {
        transition: opacity 0.25s;
      }

      &.transitioning-refinements {
        .label {
          opacity: 0.2;
        }
      }

      &.transitioning-curators {
        .name {
          opacity: 0.2;
        }
      }

      .label {
        line-height: 1;
        font-family: $font-shop-italic;
        font-style: italic;
        white-space: nowrap;
        color: $darkest;
        font-size: 20px;

        @include sm {
          font-size: 20px;
        }
        @include md {
          font-size: 22px;
        }
        @include lg {
          font-size: 24px;
        }
        @include xl {
          font-size: 26px;
        }
        @include xxl {
          font-size: 28px; // HEADER_SIZING
        }

        .curated-by-part {
          position: relative;

          &.clickable {
            cursor: pointer;
            transform: color 0.5s;

            &:hover {
              color: $light;

              &::before {
                transform: scaleX(1);
                opacity: 1;
              }
            }

            &::before {
              content: '';
              position: absolute;
              top: calc(50% + 2px);
              left: 0;
              right: 0;
              height: 1px;
              transform: scaleX(0);
              opacity: 0;
              transform-origin: left;
              width: 100%;
              background-color: $darkest;
              transition: transform 0.3s, opacity 0.3s;
            }
          }
        }
      }

      .name-container {
        @extend %flex-row-middle;
        margin-top: 4px;
        gap: 8px;

        @include md {
          gap: 12px;
        }

        .badge-image {
          aspect-ratio: 1;
          border-radius: 50%;
          background-color: $white;
          overflow: hidden;
          width: 30px; // HEADER_SIZING
          min-width: 30px; // HEADER_SIZING

          @include sm {
            width: 36px; // HEADER_SIZING
            min-width: 36px; // HEADER_SIZING
          }
          @include md {
            width: 40px; // HEADER_SIZING
            min-width: 40px; // HEADER_SIZING
          }
          @include lg {
            width: 44px; // HEADER_SIZING
            min-width: 44px; // HEADER_SIZING
          }
          @include xl {
            width: 48px; // HEADER_SIZING
            min-width: 48px; // HEADER_SIZING
          }

          &.transitioning-curators,
          &.transitioning-brands {
            img {
              opacity: 0.2;
            }
          }

          img {
            width: 100%;
            height: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            transition: opacity 0.25s;
          }

          &.brand-badge {
            @extend %flex-center;

            img {
              object-fit: contain;
              width: 90%;
              height: 90%;
            }
          }
        }

        .name {
          line-height: 1;
          font-weight: 500;
          margin-top: 4px;
          color: $darkest;
          margin-top: 4px;
          font-size: 30px; // HEADER_SIZING

          @include sm {
            font-size: 36px; // HEADER_SIZING
          }
          @include md {
            font-size: 40px; // HEADER_SIZING
          }
          @include lg {
            font-size: 44px; // HEADER_SIZING
          }
          @include xl {
            font-size: 48px; // HEADER_SIZING
          }
        }
      }

      .bio,
      .metadata {
        margin: 16px 24px 0;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        white-space: pre-line;
        font-weight: normal;
        max-width: 540px;
        color: $dark;

        a {
          text-decoration: none;
          color: $dark;
        }
      }

      .metadata {
        @extend %flex-row;
        gap: 4px;
      }

      .links-and-actions {
        @extend %flex-row-middle;
        gap: 8px;
        margin-top: 16px;

        .social-links {
          @extend %flex-row-middle;
          gap: 8px;
        }

        img,
        svg {
          height: 16px;
          color: $darkest;
        }

        .favorite {
          position: relative;
          margin-top: -2px;
          cursor: pointer;

          &.loading {
            .icon {
              svg {
                opacity: 0.2;
              }
            }
          }

          .loader {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .tab-options-container {
      @extend %flex-row-center;
      gap: 4px;
      position: relative;
      border-radius: 24px;
      min-height: 40px;
      margin-top: 24px; // SPACING

      @include sm {
        margin-top: 24px; // SPACING
      }
      @include md {
        margin-top: 24px; // SPACING
      }
      @include lg {
        margin-top: 30px; // SPACING
      }
      @include xl {
        margin-top: 30px; // SPACING
      }
      @include xxl {
        margin-top: 36px; // SPACING
      }

      &::before {
        border-radius: 24px;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1) inset;
        backdrop-filter: blur(10px);
      }

      .tab-option {
        font-weight: 500;
        color: $medium;
        cursor: pointer;
        font-size: 11px;
        padding: 12px 10px;
        transition: color 0.5s;
        white-space: nowrap;

        @include sm {
          font-size: 13px;
          padding: 12px 16px;
        }
        @include md {
          font-size: 14px;
          padding: 12px 24px;
        }

        &.search {
          padding: 12px 8px 12px 24px;
        }

        &.active {
          color: $darkest;
        }

        &.disabled {
          cursor: default;
          opacity: 0.3;
        }
      }

      .active-tab-background {
        position: absolute;
        left: 0;
        top: 2px;
        height: calc(100% - 4px);
        transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
        min-width: 40px;
        z-index: -1;
        border-radius: 30px;
        background: linear-gradient(100deg, rgba(255, 255, 255, 0.85) -0.83%, rgba(255, 255, 255, 0.58) 100.32%);
        backdrop-filter: blur(15px);
        border: $border-lightest;
      }
    }
  }
}

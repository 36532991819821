@import '../../styles/shared.scss';

.shop-empty-results-container {
  @extend %flex-center-vertical;
  flex: 1;
  padding: 96px 48px;
  border-radius: 10px;
  gap: 40px;
  background-color: white;

  .header-container {
    @extend %flex-col-center;
    gap: 8px;
    max-width: 480px;

    svg {
      margin-bottom: 10px;
      font-size: 16px;
    }

    .header {
      font-size: 28px;
      font-family: $font-header;
      font-weight: 300;
      color: $black;
      text-align: center;
    }

    .subheader {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.4;
      color: $light;
      text-align: center;
    }
  }

  .actions {
    @extend %flex-center-vertical;
    justify-content: center;
    gap: 12px;
    align-self: stretch;

    .action {
      @extend %flex-row-middle;
      @extend %btn-shared;
      @extend %btn-lowercase;
      gap: 4px;
      width: 100%;
      max-width: 360px;
      font-size: 14px;
      padding: 12px 16px;

      &.primary {
        @extend %btn-darkest-new;
        @extend %btn-lowercase;
        font-size: 14px;
        padding: 12px 16px;
      }

      &.secondary {
        @extend %btn-lightest-new;
        @extend %btn-lowercase;
        font-size: 14px;
        padding: 12px 16px;
      }
    }
  }
}

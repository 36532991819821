@import '../../styles/shared.scss';

.catalog-commission-rates-container {
  @extend %flex-col;
  gap: 40px;

  .commission-rates-section {
    .section-header-container {
      position: relative;

      .disclaimer {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 12px;
        color: $medium;

        span {
          cursor: pointer;
          margin: 0 4px;
          text-decoration: underline;
          font-weight: 600;
        }

        &.hover-action:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        @include sm-down {
          display: none;
        }
      }

      .description {
        margin-top: 8px;
        font-size: 12px;
        color: $medium;

        span {
          cursor: pointer;
          margin-left: 4px;
          text-decoration: underline;
          font-weight: 600;
        }

        @include sm-down {
          display: none;
        }
      }
    }

    .section-header {
      font-family: $font-header;
      display: flex;
      align-items: center;
      font-size: 20px;
      color: $darkest;

      .count {
        margin-left: 8px;
        font-family: $font-body-new;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        color: $dark;
        font-size: 10px;
        font-weight: bold;
        padding: 2px 4px;
        border: 1px solid #4a4a4a;
        border-radius: 12px;
        min-width: 18px;
        height: 18px;
        white-space: nowrap;
        text-align: center;
      }
    }

    .section-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 16px;

      .no-results {
        font-size: 14px;
        color: $medium;
        text-align: center;
        width: 100%;
      }
    }

    .catalog-variant-rate-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      place-content: center;
      place-items: center;
      row-gap: 30px;
      column-gap: 30px;
    }

    .see-more-button-container {
      @extend %flex-row;
      grid-column: 1 / -1;
      gap: 20px;
      justify-content: center;
      margin-top: 30px;

      .see-more-button,
      .see-all-button {
        @extend %btn-basic;
        @extend %flex-row;
        gap: 10px;
        justify-content: center;
        text-align: center;
        background: $white;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        padding: 8px 16px;
        border-radius: 100px;
        border: $border-lightest;

        .remaining-request-count {
          font-family: $font-body-new;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $white;
          color: $dark;
          font-size: 10px;
          font-weight: bold;
          padding: 2px 4px;
          border: 1px solid #4a4a4a;
          border-radius: 12px;
          min-width: 18px;
          height: 18px;
          white-space: nowrap;
          text-align: center;
        }
      }
    }
  }
}

@import '../../styles/shared.scss';

.catalog-control-bar-container {
  margin: 40px 0;

  .catalog-control-bar {
    @extend %flex-row-middle;
    width: 100%;
    position: relative;

    @include md-down {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .control-bar-center-container {
      @extend %flex-col-center;
      width: 100%;

      .control-bar-tabs {
        display: flex;
        align-items: center;
        background: $white;
        border-radius: 20px;

        .tab-container {
          height: 35px;
          border-radius: 20px;
          display: flex;
          align-items: center;

          .control-bar-tab {
            padding: 6px 8px;
            cursor: pointer;
            width: 100%;
            font-size: 12px;
            text-align: center;
            margin: 0 8px;
          }

          &.active {
            background: $darkest;

            .control-bar-tab {
              font-weight: 500;
              color: $prettymuchwhite;
            }
          }
        }
      }

      .control-bar-active-filters {
        margin-top: 10px;
        .active-filter {
          @extend %flex-center;
          @extend %label;
          font-weight: 300;
          border-radius: 8px;
          border: $border-darkest;
          padding: 8px 12px;
          text-transform: none;
          font-size: 10px;
          cursor: default;

          span {
            margin-left: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $lightest;
            color: $darkest;
            font-size: 8px;
            font-weight: bold;
            padding: 4px 8px;
            border-radius: 14px;
            min-width: 16px;
            height: 16px;
            white-space: nowrap;
            text-align: center;
          }

          .clear-btn {
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }
    }

    .control-bar-search {
      @extend %flex-row;
      align-items: center;
      height: 35px;
      width: 200px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $white;
      font-size: 12px;
      border-radius: 20px;
      padding: 2px 8px;

      svg {
        margin: 0 4px;
      }

      input {
        height: 100%;
        border: none;
        width: 80%;
        z-index: 2;

        &:focus {
          outline: none;
        }
      }

      @include md-down {
        position: relative;
        margin-bottom: 10px;
      }
    }

    .control-bar-filters-container {
      position: absolute;
      top: 0;
      right: 0;

      .filters-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        font-size: 18px;
        border: 1px solid $lighter;
        border-radius: 50%;

        &.disabled {
          cursor: not-allowed;

          svg {
            color: $lighter;
          }
        }
      }

      @include md-down {
        position: relative;
        margin-bottom: 10px;
      }
    }
  }

  .control-bar-info-banner-container {
    .info-banner {
      @extend %flex-row-middle;
      border: 1px solid $lightest;
      border-radius: 10px;
      background-color: $white;
      color: $darkest;
      font-size: 14px;
      margin-top: 10px;

      .profile-image {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        margin: 10px;
      }

      .close-btn {
        cursor: pointer;
        margin-left: auto;
        padding: 10px;
        color: $light;
        font-size: 16px;
      }
    }

    @include md-down {
      display: none;
    }
  }
}

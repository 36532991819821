@import '../../../styles/shared.scss';

.curator-group-modal-header {
  @extend %flex-row-middle-spread;
  gap: 44px;

  @include md-down {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }

  .head-back {
    @extend %flex-row-middle;
    @extend %btn-basic;
    font-size: 14px;
    gap: 6px;
    color: $dark;
    font-weight: 500;

    &:hover {
      svg {
        transform: translateX(-2px) scale(1.1);
      }
    }

    svg {
      font-size: 10px;
      transition: transform 0.2s;
    }
  }

  .modal-title-container {
    flex: 1;

    .modal-title {
      font-family: $font-header;
      font-size: 30px;
      color: $darkest;
      font-weight: 300;
    }
    .modal-subtitle {
      font-size: 12px;
      color: $dark;

      .emphasize {
        font-family: $font-shop-italic;
        font-style: italic;
        font-size: 14px;
      }

      .clickable {
        @extend %btn-basic;
        color: $darkest;
        text-decoration: underline;
      }
    }
  }

  .modal-controls-container {
    @extend %flex-row-end;
    gap: 12px;

    // Editing
    .edit-external {
      @extend %btn-basic;
      @extend %flex-row-middle;
      font-size: 12px;
      color: $dark;
      font-weight: 500;
      gap: 6px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      svg {
        font-size: 10px;
      }
    }

    // Select
    .shopmy-select {
      min-width: 160px;
    }
    .shopmy-select__menu {
      z-index: 2;
    }

    // Searching
    .search-icon {
      @extend %btn-basic;
      @extend %flex-center;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      border: $border-lighter;
      font-size: 11px;
    }

    input.search-input {
      @extend %input-shared;
      border-radius: 24px;
      font-size: 14px;
      height: 30px;
      min-width: 200px;

      &::placeholder {
        font-size: 12px;
      }
    }
  }
}

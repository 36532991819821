@import '../../../styles/shared.scss';

.shop-results-disclaimer-container {
  @extend %flex-center;
  background-color: $prettymuchwhite;
  border-top: 2px solid $offwhite;
  padding: 8px 12px;

  .shop-results-disclaimer {
    font-size: 10px;
    color: $medium;
    letter-spacing: 0.3px;
    text-align: center;

    a {
      color: $medium;
      font-weight: bold;
    }
  }
}

@import '../../styles/shared.scss';

.featured-curator-group-container {
  @extend %flex-col;
  justify-content: flex-end;
  gap: 8px;
  background-color: $darkest;
  position: relative;
  min-width: 400px;
  aspect-ratio: 3 / 1;
  overflow: hidden;

  &:hover {
    .background-image img {
      // filter: brightness(0.8);
    }

    .actions .action.show-action-on-card-hover {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .background-image {
    @extend %flex-center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &.is-loading {
      background-color: $darker;

      img {
        opacity: 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: filter 0.25s, opacity 0.25s, transform 0.25s;
      opacity: 0.75;
    }
  }

  .content-container {
    @extend %flex-row;
    align-items: flex-end;
    padding: 40px;
    z-index: 1;

    .content {
      @extend %flex-row-middle;
      flex: 1;
      gap: 24px;
      align-items: flex-start;

      .image-container {
        background-color: $darkest;
        border-radius: 50%;
        overflow: hidden;

        &.is-loading {
          img {
            opacity: 0.1;
          }
        }
        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border: 2px solid $white;
        }
      }

      .data {
        @extend %flex-col-start;

        &.is-loading {
          .title,
          .curated-by,
          .metadata {
            opacity: 0.1;
          }
        }

        .curated-by {
          font-family: $font-shop-italic;
          font-size: 18px;
          color: $offwhite;
          font-style: italic;
        }

        .title {
          font-family: $font-header;
          font-size: 28px;
          color: white;
          font-weight: 500;
          margin-top: 4px;
        }
        .metadata {
          font-size: 12px;
          color: $offwhite;
          margin-top: 4px;
        }

        .title,
        .curated-by,
        .metadata {
          transition: opacity 0.25s;
          text-align: center;
        }
      }
    }
    .actions {
      .action {
        @extend %btn-white-shop;
      }
    }
  }
}

@import '../../styles/shared.scss';

.product-curators-container {
  @extend %flex-col;
  margin: 110px 0 0;
  padding: 0 20px;

  .product-curators {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 8px;

    @include sm {
      grid-template-columns: repeat(3, 1fr);
    }
    @include md {
      grid-template-columns: repeat(3, 1fr);
    }
    @include lg {
      grid-template-columns: repeat(4, 1fr);
    }
    @include xl {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .more-container {
    @extend %flex-center;
    margin: 36px auto 0;

    .more-label {
      font-size: 14px;
      color: $dark;
    }
  }
}

@import '../../../styles/shared.scss';

.home-featured-groups-outer-container {
  position: relative;
  overflow: hidden;
  background-color: $offwhite;
  padding-bottom: 400px;

  .home-featured-groups-inner-container {
    margin: 0 auto;
    max-width: 1600px;
    min-height: 400px;

    .featured-groups {
      @extend %flex-row;
      flex-wrap: wrap;
      gap: 12px;
      .featured-group {
        width: calc(100% / 3 - 12px * (3 - 1) / 3);

        &:nth-child(3n + 2) {
          // transform: translateY(25%);
        }
      }
    }
  }
}

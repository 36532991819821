@import '../../../styles/shared.scss';

.add-pin-modal-landing-container {
  @extend %flex-col;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-family: $font-body;

  .set-mode-header {
    @extend %flex-row-middle-spread;
    @extend %header-fancy;
    padding: 20px 20px 12px;
    color: $darkest;
    width: 100%;
    font-size: 18px;

    @include md {
      font-size: 24px;
      padding: 24px 24px 12px;
    }

    svg {
      position: absolute;
      right: 16px;
      font-size: 24px;
      cursor: pointer;

      &:hover {
        color: $darkest;
      }
    }
  }

  .set-mode-options {
    @extend %flex-col;
    padding: 12px 20px;
    gap: 20px;
    color: $darkest;

    @include md {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      padding: 12px 24px;
    }

    .set-mode-option-container {
      @extend %flex-row;
      width: calc(50% - 10px); // For Gap

      @include md-down {
        width: 100%;
      }

      .set-mode-option.button {
        @extend %flex-row-middle;
        background: $white;
        border-radius: 10px;
        color: $darkest;
        border: $border-lightest;
        padding: 8px 24px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        width: 100%;
        position: relative;
        text-decoration: none;

        @include md-down {
          padding: 12px 24px;
          flex-direction: row;
          align-items: center;
          border: $border-lighter;
        }

        &:hover {
          opacity: 0.9;
          border: $border-darkest;
          .image-container {
            transform: scale(1.03);
          }

          .arrow-right {
            opacity: 1 !important;
          }
        }

        .badge {
          @extend %flex-center;
          background: white;

          position: absolute;
          padding: 6px 8px;
          font-size: 12px;
          line-height: 12px;
          border-radius: 24px;
          border: $border-darkest;
          color: $darkest;
          font-family: $font-social;
          font-weight: bold;
          height: 24px;
          top: -12px;
          right: 12px;
        }

        .image-container {
          @extend %flex-center;
          border-radius: 8px;
          transition: transform 0.3s ease-in-out;
          width: 100px;
          min-height: 132px;
          min-width: 100px;

          &.wide {
            margin: 0 -6px;
            width: calc(100px + 6px * 2);
            min-width: calc(100px + 6px * 2);
          }

          @include xl-down {
            width: 64px;
            min-width: 64px;
            min-height: 84px;

            &.wide {
              margin: 0 -6px;
              width: calc(64px + 6px * 2);
              min-width: calc(64px + 6px * 2);
            }
          }

          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }

        .body-container {
          @extend %flex-row-middle-spread;
          flex: 1;

          .body {
            padding: 0 24px 0 24px;

            @include md-down {
              padding: 0 12px 0 24px;
            }

            .header {
              font-weight: bold;
              font-size: 16px;
              letter-spacing: 10%;
              text-transform: uppercase;
              color: $darkest;
              letter-spacing: 0.25px;
            }

            .subheader {
              font-size: 12px;
              margin-top: 8px;
              color: $light;
            }

            @include md-down {
              flex-direction: row;
              text-align: left;
            }
          }

          .arrow-right {
            transform: scaleY(1.5) scaleX(0.8);
            margin-right: -12px;

            @include md {
              transition: opacity 0.2s ease-in-out;
              opacity: 0;
            }
            svg {
              color: $lighter;

              @include md {
                color: $darkest;
              }
            }
          }
        }
      }
    }
  }

  .set-mode-footer {
    @extend %flex-col;
    justify-content: flex-end;
    flex: 1;
    margin-top: 24px;

    @include md-down {
      display: none;
    }

    .snapshop-explainer {
      background: $offwhite;
      padding: 48px;
      justify-content: flex-end;
    }
  }
}

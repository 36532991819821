@import '../../../styles/shared.scss';

.onboarding-panel-bio-container {
  @extend %flex-col;
  color: $darkest;

  .profile-data-panel {
    @extend %flex-col-center;
    margin-top: 40px;

    @include xl {
      margin-top: 56px;
    }

    .input-container {
      position: relative;

      .error-message {
        position: absolute;
        top: 100%;
        right: 0px;
        font-size: 10px;
        color: $error;
      }
    }

    textarea.name {
      @extend %input-inline;
      font-family: $font-header;
      font-weight: bold;
      margin-top: 24px;
      font-size: 28px;
      line-height: 32px;
      width: 100%;
      text-align: center;
      resize: none;
    }

    textarea.username {
      @extend %input-shared;
      margin-top: 8px;
      font-size: 14px;
      text-align: center;
      resize: none;

      &.error {
        border-color: $error;
        background-color: $errorLightest;
      }
    }

    textarea.description {
      @extend %input-shared;
      margin-top: 16px;
      width: 100%;
      resize: none;
      max-width: 540px;
    }
  }
}

@import '../../../styles/shared.scss';

.curator-modal-curator-groups-container {
  .curator-groups {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    @include md {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }

    @include xl {
      grid-template-columns: repeat(4, 1fr);
    }

    .curator-group-card {
      @extend %flex-col-spread;
      gap: 8px;
      border-radius: 10px;
      background-color: $prettymuchwhite;
      position: relative;
      min-height: 280px;
      overflow: hidden;

      @include md {
        min-height: 300px;
      }

      &.has-image {
        background-color: $darkest;
      }

      &:hover {
        .actions .action.show-action-on-card-hover {
          display: inherit !important;
        }
      }

      .background-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &.is-added,
        &.is-select-mode {
          img {
            filter: grayscale(0%);
            opacity: 1;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          filter: grayscale(100%);
          transition: filter 0.25s;
          opacity: 0.1;
        }
      }

      .actions {
        @extend %flex-row-end;
        gap: 8px;
        padding: 16px 16px 32px;
        z-index: 1;

        &.is-added,
        &.is-select-mode {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
        }

        .action {
          @extend %flex-center;
          width: 40px;
          height: 40px;
          border-radius: 4px;
          background-color: $lightest;
          color: white;
          font-size: 20px;
          cursor: pointer;
          transition: background-color 0.25s;

          &.has-image {
            background-color: transparent;
            border: $border-light;

            &.is-added,
            &.is-select-mode {
              border: $border-white;
            }

            &:hover {
              border-color: $light;
            }

            &.is-adding {
              background-color: rgba(255, 255, 255, 0.1);
            }

            &.is-added,
            &.is-select-mode {
              background-color: rgba(255, 255, 255, 0.25);
            }
          }

          &:not(.has-image) {
            &:hover {
              background-color: $lighter;
            }

            &.is-adding {
              background-color: $medium;
            }

            &.is-added {
              background-color: $darker;
            }
          }

          &.disabled {
            cursor: default;
          }

          &.show-action-on-card-hover {
            display: none;
          }
        }
      }

      .content {
        padding: 40px 16px 16px;
        z-index: 1;

        &.has-image {
          &.is-added,
          &.is-select-mode {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
          }

          .curator,
          .count {
            color: $offwhite;
          }
          .title {
            color: white;
          }
        }

        &.clickable {
          cursor: pointer;
          transition: opacity 0.25s;

          &:hover {
            opacity: 0.8;
          }
        }

        .curator {
          font-size: 11px;
          color: $medium;
        }

        .title {
          font-size: 16px;
          color: $darkest;
          font-weight: bold;
        }
        .count {
          font-size: 11px;
          color: $medium;
        }
      }

      .action-icon {
        @extend %btn-basic;
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 24px;
        color: $darkest;
      }
    }
  }
}

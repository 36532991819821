@import '../../styles/shared.scss';

.product-outer-container {
  min-height: 100vh;
  background-color: $offwhite;
  font-family: $font-body-new;
  padding-bottom: 25vh;
  padding-top: $shoppingNavHeight;

  .product-inner-container {
    width: 100vw;
    margin: 0 auto;

    .back-to-shop-btn {
      @extend %btn-basic;
      @extend %flex-row;
      gap: 8px;
      font-size: 14px;
      font-weight: 500;
      color: $dark;
      text-decoration: none;
      align-items: center;
      margin-bottom: 24px;
    }

    .full-width-section-outer {
      &.white {
        background-color: white;
      }

      .full-width-section-inner {
        max-width: 1240px;
        margin: 0 auto;
      }
    }

    .inner-section {
      max-width: min(1240px, calc(100% - 48px));
      margin: 0 auto;
    }

    .section-separator {
      height: 1px;
      width: 100%;
      max-width: 90vw;
      margin: 0 auto;
      background-color: $lighter;
    }

    .secondary-sections {
      @extend %flex-col;

      .section-header-container {
        @extend %flex-center-vertical;
        gap: 4px;
        margin-bottom: 40px;

        .section-header {
          font-size: 27px;
          font-family: $font-header;
          font-weight: 300;
          text-align: center;
        }

        .section-subheader {
          font-size: 15px;
          font-weight: 400;
          color: $dark;
          line-height: 1.4;
          text-align: center;
        }
      }
    }
  }
}

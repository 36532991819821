@import '../../styles/shared.scss';

.shop-collection-results-outer-container {
  @extend %flex-col;
  align-items: stretch;
  background-color: white;
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 24px;

  @include sm {
    padding-top: 30px;
  }
  @include md {
    padding-top: 36px;
  }
  @include lg {
    padding-top: 40px;
  }
  @include xl {
    padding-top: 48px;
    border-radius: 0 0 10px 10px;
  }
  @include xxl {
    padding-top: 64px;
  }

  &.posts,
  &.post_vertical,
  &.post_horizontal {
    padding-top: 0px;
  }

  .shop-collection-results-inner-container {
    @extend %flex-col;
    align-items: stretch;
    gap: 20px;

    @include sm {
      gap: 24px;
    }
    @include md {
      gap: 30px;
    }
    @include lg {
      gap: 36px;
    }
    @include xl {
      gap: 40px;
    }
    @include xxl {
      gap: 48px;
    }

    .shop-collection-columns {
      @extend %flex-row;
      align-self: stretch;
      width: 100%;
      gap: 1px;

      @include md {
        gap: 2px;
      }

      @include xl {
        gap: 3px;
      }

      &.columns-1 {
        @extend %flex-col;
        align-items: stretch;

        .shop-collection-column {
          @extend %flex-col;
          gap: 16px;
        }
      }

      &.columns-3 {
        .shop-collection-column {
          @extend %flex-col;
          flex: 1;
          gap: 1px;

          @include md {
            gap: 2px;
          }

          @include xl {
            gap: 3px;
          }
        }
      }

      .load-next-page-container {
        @extend %flex-center;
        background-color: $prettymuchwhite;
        min-height: 240px;
        border-radius: 10px;
      }
    }

    .error-message {
      margin-top: 20px;
      text-align: center;

      .error-message-text {
        font-size: 14px;
        color: $medium;
      }
    }
  }
}

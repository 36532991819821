@import '../../styles/shared.scss';

.product-qa-outer-container {
  max-width: 1240px;
  margin: 0 auto;

  .product-qa-inner-container {
    padding: 120px 20px;

    @include sm {
      padding: 120px 20px;
    }
    @include md {
      padding: 120px 5vw;
    }
    @include lg {
      padding: 120px 5vw;
    }
    @include xl {
      padding: 140px 5vw;
    }
    @include xxl {
      padding: 160px 5vw;
    }

    .qa-sections {
      // Grid of 4
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 64px;

      @include md {
        grid-template-columns: repeat(2, 1fr);
        gap: 48px;
      }

      .qa-section {
        @extend %flex-col;
        gap: 8px;
        transition: background-color 0.5s, transform 0.75s;

        @include md {
          max-width: 440px;
        }

        &:nth-child(2) {
          transition-delay: 0.5s;

          .question,
          .answer {
            transition-delay: 0.5s;
          }
        }

        &.loading {
          min-height: 200px;
          background-color: $whynotusewhite;
          border-radius: 10px;

          .question {
            opacity: 0;
            transform: translateY(30px);
          }
          .answer {
            opacity: 0;
            transform: translateY(35px);
          }
        }

        .question {
          font-size: 14px;
          font-weight: 500;
          color: $medium;
          transition: opacity 0.5s, transform 0.75s;

          @include md {
            font-size: 16px;
          }
        }
        .answer {
          font-size: 18px;
          line-height: 1.4;
          color: $dark;
          transition: opacity 0.5s, transform 0.75s;

          @include md {
            font-size: 22px;
          }
        }
      }
    }
  }
}

@import '../../../styles/shared.scss';

button.checkbox-button-container-outer {
  outline: none;
  border: none;
  background: none;
  font-family: $font-body-new !important;

  .checkbox-button-container-inner {
    @extend %flex-row;
    margin-bottom: 4px;
    cursor: pointer;
    text-align: left;

    &.selected {
      .check {
        background: $darkest;
        border: none;
      }

      .check-text {
        color: $darker;
        font-weight: 700;
      }
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }

    .check {
      @extend %flex-center;
      margin-top: 2px;
      border: $border-light;
      height: 16px;
      width: 16px;
      border-radius: 3px;

      svg {
        color: $white;
        font-size: 8px;

        path {
          stroke-width: 5;
        }
      }
    }

    .check-text {
      @extend %flex-col;
      gap: 3px;
      margin-left: 12px;
      flex: 1;
      color: $darkest;
      justify-content: center;
      font-weight: 400;
      align-self: center;
      height: 100%;

      .check-label {
        font-size: 14px;
        font-weight: 700;
      }

      .check-description {
        font-size: 12px;
        font-weight: 400;
        color: $medium;
      }

      .check-regular {
        font-size: 14px;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;

      .check {
        cursor: default;
      }
    }
  }
}

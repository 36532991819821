@import '../../../styles/shared.scss';

/*
  Format One: Quick Shop
*/
.shop-collection-result.quick-shop {
  @extend %flex-col;
  gap: 24px;
  margin-bottom: 48px;

  .collection-result-header-container {
    @extend %flex-row-middle-spread;

    .result-data {
      @extend %flex-row;
      align-items: flex-start;
      gap: 16px;

      .image-container {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .metadata-container {
        @extend %flex-col;
        gap: 2px;

        .title {
          font-size: 20px;
          font-weight: 500;
          margin: 0;
        }

        .subtitle {
          font-size: 12px;
          color: $darker;
          font-weight: 400;
          margin: 0;
        }
      }
    }

    .result-actions {
      @extend %flex-row-middle-spread;
      gap: 16px;

      .action {
        @extend %btn-darkest-shop;
        text-decoration: none;
      }
    }
  }

  .preview-pins {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 2px * 3 / 4));
    gap: 2px;
    width: 100%;

    .collection-image-container {
      background-color: $darkest;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1;
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .preview-pin {
      @extend %flex-col;
      background-color: white;
      padding: 24px;
      border: $border-transparent;
      position: relative;
      gap: 20px;
      color: $darkest;
      text-decoration: none;

      .image-container {
        width: 90%;
        aspect-ratio: 1;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 8px;
          aspect-ratio: 1;
          transition: transform 0.3s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .data {
        @extend %flex-col;
        align-self: stretch;
        gap: 4px;

        .brand {
          font-size: 14px;
          color: $light;
          font-weight: 500;
          text-decoration: none;
          white-space: nowrap;
          text-align: center;
        }

        .title {
          font-size: 16px;
          font-weight: 500;
          color: $black;
          text-decoration: none;
          line-height: 1.4;
          text-align: center;
        }

        .category {
          font-size: 12px;
          color: $medium;
          font-weight: 500;
          white-space: nowrap;
          text-align: center;
        }
      }

      .favorite-icon {
        position: absolute;
        top: 4px;
        right: 4px;
        padding: 10px;
        z-index: 1;
        color: $lighter;
        cursor: pointer;

        &.favorited {
          color: $darkest;
        }

        &:hover:not(.favorited) {
          opacity: 0.7;
        }
      }
    }
  }

  .show-more-container {
    @extend %flex-center;
    .show-more {
      @extend %btn-basic;
      font-size: 12px;
      color: $medium;
      transition: opacity 0.25s;

      &.fetching {
        opacity: 0.5;
      }
    }
  }
}
/*
  Format Two: Shelf
*/
.shop-collection-result.shelf {
  @extend %flex-col;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  color: $darkest;

  &:hover {
    img {
      transform: scale(1.02);
    }

    .collection-result-body-container .go-to-collection {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .preview-pins {
    background-color: white;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 2px * 1 / 2));
    gap: 2px;
    width: 100%;
    padding: 0 24px;

    @include sm {
      padding: 0 24px;
      grid-template-columns: repeat(3, calc(33.3% - 2px * 2 / 3));
    }
    @include md {
      padding: 0 24px;
      grid-template-columns: repeat(4, calc(25% - 2px * 3 / 4));
    }
    @include lg {
      padding: 0 30px;
      grid-template-columns: repeat(5, calc(20% - 2px * 4 / 5));
    }
    @include xl {
      padding: 0 36px;
    }
    @include xxl {
      padding: 0 44px;
    }

    .preview-pin {
      @extend %flex-col-center;
      position: relative;
      text-decoration: none;
      border-radius: 20px;

      @include sm-down {
        padding: 4px 4px 0;

        &:nth-child(n + 3) {
          display: none;
        }
      }
      @include md-down {
        padding: 8px 8px 0;

        &:nth-child(n + 4) {
          display: none;
        }
      }

      @include lg-down {
        &:nth-child(n + 5) {
          display: none;
        }
      }

      @include lg {
        padding: 8px 8px 0;
      }

      .image-container {
        width: 100%;
        aspect-ratio: 1;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 8px;
          aspect-ratio: 1;
        }
      }
    }
  }

  .collection-result-body-container {
    @extend %flex-row-middle-spread;
    background-color: white;
    transform: translateY(-16px);
    padding: 16px 24px 16px;

    @include sm {
      transform: translateY(-18px);
      padding: 18px 24px 18px;
    }
    @include md {
      transform: translateY(-20px);
      padding: 20px 24px 20px;
    }
    @include lg {
      transform: translateY(-20px);
      padding: 20px 30px 20px;
    }
    @include xl {
      transform: translateY(-20px);
      padding: 24px 36px 24px;
    }
    @include xxl {
      transform: translateY(-24px);
      padding: 24px 44px 24px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 100%;
      width: 100%;
      height: 36px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0));
      z-index: -1;
      pointer-events: none;
    }

    .result-data {
      @extend %flex-row;
      align-items: flex-start;
      gap: 16px;

      .image-container {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .metadata-container {
        @extend %flex-col;
        gap: 2px;

        .title {
          font-size: 20px;
          font-weight: 500;
          margin: 0;
        }

        .subtitle {
          font-size: 12px;
          color: $darker;
          font-weight: 400;
          margin: 0;
        }
      }
    }

    .go-to-collection {
      @extend %flex-center;
      gap: 8px;
      font-size: 14px;
      opacity: 0;
      transition: opacity 0.25s, transform 0.25s;
      transform: translateX(-4px);

      svg {
        font-size: 12px;
      }

      @include md-down {
        display: none;
      }
    }
  }
}

/*
  Format Two: Posts (Horizontal)
*/
.shop-collection-result.post {
  aspect-ratio: 1;
  overflow: hidden;
  position: relative;
  min-height: 100px;

  &:hover {
    .image-container {
      img {
        transform: scale(1.05);
      }
    }
  }

  .image-container {
    background-color: white;

    &.image-loaded {
      img {
        opacity: 1;
      }
    }

    img {
      opacity: 0;
    }
  }

  a {
    text-decoration: none;
    color: $offwhite;
  }

  &.post_vertical {
    aspect-ratio: 0.754;
  }

  &.post_horizontal {
    aspect-ratio: 1.78;
  }

  .image-container {
    img {
      width: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
      transition: transform 0.25s, opacity 0.5s;

      &.column-0 {
        transition-delay: 0s;
      }
      &.column-1 {
        transition-delay: 0.1s;
      }
      &.column-2 {
        transition-delay: 0.2s;
      }
    }
  }

  .data-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px;
    z-index: 2;
    padding-top: 64px;
    background: transparent;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    opacity: 0;
    transition: opacity 2s;

    @include md {
      padding: 16px;
      padding-top: 64px;
    }

    &.column-0 {
      transition-delay: 0s;
    }
    &.column-1 {
      transition-delay: 0.2s;
    }
    &.column-2 {
      transition-delay: 0.4s;
    }

    .title {
      color: white;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }

    .subtitle {
      color: white;
      font-size: 10px;
      font-weight: 400;
      margin: 0;

      @include md {
        font-size: 12px;
      }
    }

    &.image-loaded {
      opacity: 1;
    }
  }
  // &:before {
  //   transition: opacity 0.3s;
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   // opacity: 0;
  //   // z-index: 2;
  // }
}

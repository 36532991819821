@import '../../../styles/shared.scss';

.curator-group-modal-curators {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 12px + (12px / 2)));
  gap: 12px;
  margin-bottom: 24px;

  @include sm {
    grid-template-columns: repeat(3, calc(33.3333% - 12px + (12px / 3)));
  }

  @include md {
    grid-template-columns: repeat(4, calc(25% - 12px + (12px / 4)));
  }

  .curator-group-curator,
  .curator-group-group {
    @extend %flex-center-vertical;
    gap: 12px;
    min-height: 160px;
    cursor: pointer;

    &:hover {
      img {
        transform: scale(0.95);
      }

      .data-container {
        .name {
          text-decoration: underline;
        }
      }
    }

    .images-container {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;

      .empty-image {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: $lightest;
        z-index: 2;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        transition: all 0.25s ease-in-out;
        z-index: 1;

        &:nth-child(2) {
          transform: scale(0.75) translateX(-50%);
          opacity: 0.75;
          z-index: 0;
        }
        &:nth-child(3) {
          transform: scale(0.75) translateX(50%);
          opacity: 0.75;
          z-index: 0;
        }
      }
    }

    .data-container {
      .name {
        font-size: 13px;
        font-weight: 500;
        color: $darker;
        text-align: center;
      }
    }
  }
}

@import '../../styles/shared.scss';

.new-curator-card-container {
  @extend %flex-col-spread;
  background-color: $darkest;
  position: relative;
  min-width: 140px;
  border-radius: 10px;
  aspect-ratio: 1;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    .background-image img {
      // filter: brightness(0.8);
      transform: scale(1.05);
    }

    .actions .action.show-action-on-card-hover {
      opacity: 1;
      pointer-events: auto;
    }

    .show-on-hover {
      opacity: 1;
    }
  }

  .show-on-hover {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  .background-image {
    @extend %flex-center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &.is-loading {
      background-color: $darker;

      img {
        opacity: 0;
      }
    }

    &.hidden-except-admins {
      img {
        opacity: 0.2;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: filter 0.25s, opacity 0.25s, transform 0.25s;
      opacity: 0.8;
    }

    .hidden-except-admins-overlay {
      @extend %label;
      position: absolute;
      z-index: 1;
      font-size: 10px;
    }
  }

  .actions {
    @extend %flex-row-end;
    gap: 8px;
    padding: 16px 16px 0;
    z-index: 1;

    &.is-loading {
      .action {
        opacity: 0.5;
      }
    }

    .action {
      @extend %flex-center;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background-color: transparent;
      color: white;
      font-size: 16px;
      background-color: rgba(255, 255, 255, 0.3);
      cursor: pointer;
      position: relative;
      transition: opacity 0.25s;

      &:not(.disabled):not(.is-check):hover {
        background-color: rgba(255, 255, 255, 0.6);
      }

      &.is-check {
        background-color: $successFaded;
      }

      svg {
        color: white;
      }

      &.disabled {
        cursor: default;

        svg {
          color: $light;
        }
      }

      &.show-action-on-card-hover {
        opacity: 0;
        transition: opacity 0.25s;
        pointer-events: none;
      }

      &:hover {
        .explainer {
          opacity: 1;
        }
      }

      .explainer {
        @extend %flex-center;
        position: absolute;
        top: 100%;
        margin-top: 4px;
        text-align: center;
        left: 0;
        width: 100%;
        white-space: nowrap;
        font-size: 11px;
        font-weight: bold;
        opacity: 0;
        transition: opacity 0.25s;
      }
    }
  }

  .content {
    @extend %flex-col;
    padding: 30px 16px 16px;
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));

    @include md {
      padding: 64px 16px 16px;
    }

    &.clickable {
      cursor: pointer;
      transition: opacity 0.25s;

      &:hover {
        opacity: 0.8;
      }
    }

    &.is-loading {
      .title,
      .curated-by,
      .metadata {
        opacity: 0.1;
      }
    }

    .curated-by {
      font-family: $font-shop-italic;
      font-size: 13px;
      line-height: 1;
      color: $offwhite;
      font-style: italic;

      @include sm {
        font-size: 14px;
      }

      @include md {
        font-size: 14px;
      }
    }

    .title {
      font-size: 16px;
      line-height: 1.2;
      color: white;
      font-weight: 500;
      margin-top: 4px;

      @include sm {
        font-size: 18px;
      }

      @include md {
        font-size: 20px;
      }
    }
    .metadata {
      font-size: 12px;
      color: $offwhite;
      margin-top: 4px;
    }

    .title,
    .curated-by,
    .metadata {
      transition: opacity 0.25s;
      // text-align: center;
    }
  }
}

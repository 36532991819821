@import '../../../../styles/shared.scss';

.advanced-split-input {
  @extend %flex-row;
  gap: 5px;
  height: 51px;

  > button {
    flex: 0 0 100px;
  }
}

.disclaimer {
  margin: 4px 0 0 0;
  color: $light;
  font-size: 12px;
}

button.checkbox-button-container-outer {
  padding: 0;
}

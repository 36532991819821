@import '../../styles/shared.scss';

.shop-results-container {
  min-height: 800px;
  box-sizing: border-box;
  margin-top: 2px;

  .product-results {
    display: grid;
    gap: 2px;
    grid-template-columns: repeat(2, calc(50% - 2px / 1)); /* Adjust for gaps */

    @include md {
      grid-template-columns: repeat(3, calc(33.3% - 2px / (3 / 2))); /* Adjust for gaps */
    }
  }
  .error-message {
    margin-top: 20px;
    text-align: center;

    .error-message-text {
      font-size: 14px;
      color: $medium;
    }
  }
}

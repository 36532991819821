@import '../../styles/shared.scss';
@import '../../Components/variables.scss';

.collection-content {
  @extend %flex-col;
  width: 100%;
  padding-bottom: 120px;

  &.is-shop {
    padding-top: $shoppingNavHeight;
    background-color: $prettymuchwhite;
    min-height: 100vh;
  }

  .collection-content-inner-container {
    @extend %flex-col;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 40px;

    &.longform {
      max-width: 800px;
    }

    .collection-loader-container {
      @extend %flex-center;
      height: 80vh;

      .collection-loading-failure {
        @extend %btn-outline;
        padding: 8px 16px;
      }
    }
  }
}

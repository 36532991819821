@import '../../styles/shared.scss';

.nav-top {
  width: 100%;
  z-index: 5;
  position: fixed;
  transition: all 0.15s ease-in-out;
  font-family: $font-body-new;

  .nav-top-outer-container {
    @extend %flex-row-middle-spread;
    width: 100%;
    z-index: 10;
    position: relative;
    padding: 20px 0;
    height: $navHeight;

    .background {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      background: $darkest;
      transition: transform 0.2s ease-in-out;
      height: $navHeight;

      &.transparent {
        display: none;
      }

      &.scrolled {
        transform: translateY(-16px);
      }
    }

    &.home,
    &.referral,
    &.welcome,
    &.promote {
      padding: 36px 0 24px;
      height: auto;

      &.scrolled {
        .before {
          opacity: 0.2;
        }
      }

      &.home {
        .before {
          opacity: 0.1;
        }
      }

      &.referral,
      &.welcome,
      &.promote {
        .before {
          opacity: 0.8;
        }
      }

      .before {
        background: linear-gradient(180deg, $darkest, transparent);
        transition: opacity 0.3s;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100px;
        opacity: 0;
        pointer-events: none;

        &.promote {
          background: linear-gradient(180deg, var(--background-color), transparent);
        }
      }
    }

    .nav-top-inner-container {
      @extend %flex-row-middle-spread;
      width: 100%;
      max-width: 1240px;
      margin: 0 auto;
      color: white;
      position: relative;

      @include md-down {
        padding: 0 20px;
      }

      @include md {
        padding: 0 60px;
      }

      @include lg {
        padding: 0 40px;
      }

      @include xl {
        padding: 0 12px;
      }

      @include xxl {
        max-width: 1440px;
        padding: 0;
      }

      .shrink-on-scroll {
        transition: transform 0.15s ease-in-out;

        &.scrolled {
          transform: translateY(-8px) scale(0.9);

          &.home {
            transform: translateY(-16px) scale(0.9);
          }
        }
      }

      .header-main-container {
        @extend %flex-row;

        @include md {
          justify-content: flex-start;
        }

        &.focused-on-search {
          img {
            display: none;
          }
        }
      }

      button {
        background: transparent;
        appearance: none;
        font: inherit;
        border: none;
        padding: 0;
        font-size: inherit;
        color: inherit;
        outline: none;
      }

      .header-secondary-container {
        @extend %flex-row-middle;

        .nav-tabs {
          @extend %flex-row;
          align-items: stretch;
          z-index: 2;

          .nav-tab-group {
            @extend %flex-row;
            align-items: stretch;
            min-height: 40px;

            &.main {
              align-items: center;

              @include xl {
                margin-right: 48px;
              }

              .nav-tab {
                margin: 0;
                padding: 0 12px;
                line-height: 14px;

                &:not(:last-child) {
                  // border-right: 1px solid white;
                }
              }
            }

            &:not(.home) {
              .hide-on-large {
                @include lg {
                  display: none !important;
                }
              }

              .show-on-large {
                @include lg-down {
                  display: none !important;
                }
              }

              &.logged-in {
                .nav-tab.active {
                  border-radius: 24px;

                  // underline
                  &:before {
                    content: '';
                    position: absolute;
                    margin: 0 8px;
                    bottom: -3px;
                    pointer-events: none;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background: white;
                    border-radius: 2px;

                    @include xxl {
                      margin: 0 12px;
                    }
                  }
                }
              }
            }

            &.home {
              .hide-on-large {
                @include xl {
                  display: none !important;
                }
              }

              .show-on-large {
                @include xl-down {
                  display: none !important;
                }
              }

              @include md-down {
                .nav-tab {
                  display: none;

                  a,
                  svg {
                    color: $darkest;
                  }

                  &.btn {
                    color: $darkest;
                    display: flex;

                    &.apply {
                      border: $border-dark;
                    }
                  }
                }
                .nav-hamburger-wrapper img.nav-hamburger {
                  filter: invert(0);
                }
              }
            }

            &.focused-on-search {
              .nav-tab {
                display: none;
              }
            }

            &.white-elements {
              .nav-tab {
                a,
                svg {
                  color: $white;
                }

                &:not(:last-child) {
                  border-color: $white;
                }

                &.btn {
                  color: $white;

                  &.apply {
                    border: $border-white;
                  }
                }
              }
              .nav-hamburger-wrapper {
                img.nav-hamburger {
                  filter: invert(1);
                }
              }
              .user-account-summary-header {
                color: $white;
              }
            }

            &.dark-elements {
              .nav-tab {
                a,
                svg {
                  color: $black;
                }

                &:not(:last-child) {
                  border-color: $black;
                }

                &.btn {
                  color: $black;

                  &.apply {
                    border: $border-black;
                  }
                }
              }
              .nav-hamburger-wrapper {
                img.nav-hamburger {
                  filter: invert(0);
                }
              }
              .user-account-summary-header {
                color: $black;
              }
            }

            .nav-tab {
              @extend %flex-center;
              gap: 8px;
              letter-spacing: 0.4px;
              position: relative;
              font-size: 13px;
              white-space: nowrap;
              font-weight: bold;
              margin: 8px 4px;
              padding: 1px 8px 1px;
              text-decoration: none;
              transition: color 0.25s ease-in-out;
              cursor: pointer;

              &.is-last {
                margin-right: 24px;
              }

              a {
                text-decoration: none;
                color: white;
              }

              @include md-down {
                font-size: 13px;
                padding: 0 8px;
              }

              @include xxl {
                font-size: 14px;
                padding: 1px 12px 1px;
              }

              .new-badge {
                @extend %label;
                background-color: $primary;
                columns: white;
                position: absolute;
                top: -14px;
                right: 8px;
                padding: 2px 6px;
                border-radius: 8px;
                font-size: 10px;
              }

              &.btn {
                @extend %btn-basic;
                padding: 8px 10px;
                margin: 0 4px;
                transition: color 0.25s ease-in-out, border 0.25s ease-in-out;

                @include xl {
                  padding: 10px 20px;
                }

                &.apply,
                &.invite {
                  border: $border-white;
                }
              }

              &:hover {
                .header-subpage-panel {
                  display: flex;
                }
              }
            }

            .action-btn {
              @extend %btn-basic;
              margin-right: 16px;
              text-transform: uppercase;
              padding: 2px 8px;
              border: $border-white;
              border-radius: 4px;
              font-size: 12px;
              font-weight: bold;
              transform: translateY(2px);

              @include md-down {
                display: none;
              }
            }

            .search-container {
              margin: 0 12px;
            }

            .nav-hamburger-wrapper {
              @extend %flex-center;
              position: relative;
              margin-left: 12px;
              color: white;

              &.focused-on-search {
                display: none;
              }

              img.nav-hamburger {
                cursor: pointer;
                transition: transform 0.15s ease-in-out, filter 0.25s ease-in-out;
                width: 20px;
                height: 16px;
                filter: invert(1);

                &.close {
                  transform: scale(1.1);
                }
              }

              .notification-count {
                @extend %flex-center;
                position: absolute;
                letter-spacing: -0.1em;
                top: 0px;
                right: -6px;
                background: $error;
                transition: transform 0.15s ease-in-out;
                font-size: 10px;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                font-weight: bold;
                pointer-events: none;

                .count {
                  margin-left: -0.15em;
                }
              }
            }

            .manage-user-menu-wrapper {
              @extend %btn-basic;
              width: 40px;
              height: 40px;
              margin-left: 12px;
              position: relative;
              overflow: hidden;

              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }

              .initials {
                @extend %flex-center;
                background: white;
                height: 100%;
                width: 100%;
                color: $darkest;
                font-weight: bold;
                font-size: 16px;
                font-family: $font-header;
                cursor: pointer;
              }
              .notification-count {
                @extend %flex-center;
                position: absolute;
                letter-spacing: -0.1em;
                top: 100%;
                right: -3px;
                border: $border-white;
                background: $darkest;
                transition: transform 0.15s ease-in-out;
                font-size: 9px;
                font-weight: bold;
                border-radius: 4px;
                width: 16px;
                height: 16px;
                font-weight: bold;
                pointer-events: none;
                display: none;
              }
            }

            @include lg-down {
              .user-account-summary-header {
                display: none;
              }
            }

            .user-account-summary-header {
              @extend %flex-row-middle;
              font-size: 12px;
              gap: 12px;
              cursor: pointer;
              margin: 0 12px;

              .profile-img-wrapper {
                border-radius: 50%;
                height: 40px;
                width: 40px;
                padding: 2px;
                background: $white;
                transition: opacity 0.15s ease-in-out;

                &:hover {
                  opacity: 0.9;

                  img {
                    transform: scale(0.95);
                  }
                }

                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  border-radius: 50%;
                  transition: transform 0.15s ease-in-out;
                }

                &.brand {
                  background: white;

                  img {
                    object-fit: contain;
                  }
                }
              }

              .data-container {
                @extend %flex-col-middle;
                gap: 4px;
                font-size: 12px;

                &.clickable {
                  cursor: pointer;
                }

                .score-label {
                  font-weight: bold;
                  line-height: 1;
                }

                .score {
                  line-height: 1;
                  font-size: 12px;

                  &:hover {
                    .value {
                      opacity: 0.8;
                    }
                  }

                  .value {
                    transition: opacity 0.15s ease-in-out;
                    font-weight: bold;

                    .points {
                      font-size: 10px;
                      opacity: 0.6;
                      font-weight: 500;
                      transition: opacity 0.15s ease-in-out;
                    }
                  }
                  .per-hundred {
                    font-size: 8px;
                    opacity: 0.6;
                  }
                }

                .budget {
                  @extend %animation-fade;
                  font-size: 12px;
                  height: 14px;
                  position: relative;
                  align-self: flex-start;
                  font-weight: 500;
                  text-align: left;

                  &:hover {
                    .value {
                      opacity: 0.8;
                    }
                  }

                  .value {
                    transition: opacity 0.15s ease-in-out;

                    &.negative {
                      color: $errorLight;
                    }
                  }

                  .loading-budget {
                    @extend %flex-center;
                    width: 14px;
                    margin-left: 1px;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '../../styles/shared.scss';

.shop-catalog-results-container {
  .catalog-result-filters {
    @extend %flex-row-middle;
    gap: 16px;
    padding: 16px;
    background-color: white;
    overflow-x: auto;

    @include xl {
      border-radius: 20px 20px 0 0;
    }

    .filter {
      @extend %flex-row-middle;
      padding: 8px 16px;
      gap: 8px;
      border: $border-lighter;
      background-color: $white;
      font-size: 14px;
      border-radius: 24px;
      color: $darker;
      cursor: pointer;
      transition: all 0.2s;
      white-space: nowrap;
      text-decoration: none;

      &.has-image {
        padding: 4px 16px 4px 8px;
      }

      .image-container {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &.active {
        border: $border-darkest;
        color: $darkest;

        .label {
          font-weight: 500;
        }

        &:hover {
          border: $border-medium;
          .remove-icn {
            color: $darkest;
          }
        }
      }

      .label {
        font-size: 13px;
        transition: opacity 0.25s;

        .sublabel {
          color: $light;
        }
      }

      .remove-icn {
        @extend %flex-row-end;
        font-size: 12px;
        color: $medium;
        cursor: pointer;
        min-width: 10px;
        transition: opacity 0.25s;
      }
    }
  }

  .shop-catalog-results {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-top: 1px solid $lighter;
    gap: 2px;

    @include sm {
      grid-template-columns: repeat(3, 1fr);
    }

    @include md {
      grid-template-columns: repeat(4, 1fr);
    }

    @include lg {
      grid-template-columns: repeat(5, 1fr);
    }

    .shop-catalog-result {
      @extend %flex-col-spread;
      background-color: white;
      gap: 8px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      text-decoration: none;
      color: $darkest;

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        transform: translateY(10px);
        z-index: 1;
        box-shadow: 0 -10px 40px rgba(0, 0, 0, 0.1);
      }

      &:hover {
        img {
          transform: scale(1.05);
        }

        .shop-catalog-result-inner {
          .admin-actions {
            opacity: 1;
          }
        }
      }

      .shop-catalog-result-inner {
        @extend %flex-col;
        gap: 30px;
        padding: 30px 16px;
        position: relative;

        @include sm {
          padding: 36px 16px;
        }
        @include md {
          padding: 40px 16px;
        }
        @include lg {
          padding: 44px 16px;
        }

        .admin-actions {
          position: absolute;
          bottom: 8px;
          right: 8px;
          opacity: 0;
          transition: opacity 0.25s;

          .action {
            @extend %btn-basic;
            font-size: 12px;
            color: $lighter;
          }
        }

        .image-container {
          width: 90%;
          aspect-ratio: 1;
          margin: 0 auto;

          img {
            height: 100%;
            width: 100%;
            aspect-ratio: 1;
            object-fit: contain;
            transition: transform 0.25s;
          }
        }

        .data-container {
          .title {
            font-family: $font-header;
            font-size: 18px;
            color: $darkest;
            text-align: center;
            font-weight: 300;
            letter-spacing: 0.5px;
          }

          .count {
            margin-top: 12px;
            font-size: 10px;
            text-align: center;
            color: $light;
          }
        }
      }
    }
  }
}

@import '../../styles/shared.scss';

.all-settings-outer {
  background-color: $offwhite;
  min-height: 100vh;
  font-family: $font-body-new;

  .all-settings-inner {
    @extend %flex-row;
    gap: 5px;
    padding-top: 80px;
    padding-bottom: 350px;
    width: min(90%, 1000px);
    margin: 0 auto;

    @include md-down {
      flex-direction: column-reverse;
    }

    .settings-content {
      @extend %flex-col;
      gap: 36px;
      background: white;
      padding: 34px 44px 44px 44px;
      flex: 1;
      border-radius: 0 10px 10px 0;

      @include md-down {
        border-radius: 10px;
      }

      .active-section-title {
        font-size: 28px;
        font-family: $font-header;
        line-height: 36px;
        font-weight: 700;

        &.shouldShowActiveSectionTitle {
          margin-bottom: 36px;
        }

        &.skeleton {
          height: 36px;
          width: 350px;
          background: $offwhite;
        }

        .active-section-subtitle {
          font-family: $font-body-new;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: $light;
        }
      }

      .active-section {
        @extend %flex-col;
        gap: 36px;
        font-family: $font-body-new;

        .settings-section {
          @extend %flex-col;
          gap: 8px;
          width: 100%;

          + .settings-section {
            margin-top: 36px;
          }

          .settings-section-title {
            color: $darkest;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;

            &.highlighted {
              background-color: $yield;
              border-radius: 4px;
              align-self: flex-start;
              padding: 2px 8px;
            }
          }

          .settings-section-subtitle {
            color: $light;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            margin-top: -4px;
          }

          .settings-section-split-input {
            @extend %flex-row;
            gap: 5px;

            button.settings-button {
              width: 121px;
            }
          }

          .settings-section-split-input-password {
            flex: 1;
            position: relative;

            input {
              width: 100%;
            }

            svg {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
              height: 12px;
            }
          }

          input.settings-input {
            flex: 1;
            padding: 16px;
            font-family: $font-body-new;
            border-radius: 4px;
            border: $border-lightest;
            outline: none;
          }

          button.settings-button,
          a.settings-button {
            @extend %label;
            @extend %btn-basic;
            font-family: $font-body-new;
            padding: 12px 18px;
            border-radius: 5px;
            border: $border-darkest;
            outline: none;
            color: $darkest;
            background: transparent;
            font-size: 14px;
            transition: all 0.2s;
            text-decoration: none;

            &.restricted {
              width: max-content;
              margin-top: 10px;
            }

            &.dark {
              color: $white;
              background: $darkest;
            }

            &.fit {
              width: max-content;
            }

            &:disabled {
              cursor: not-allowed;
              opacity: 0.5;
            }

            @include md-down {
              font-size: 12px;
              padding: 10px 16px;
            }
          }

          button.settings-tag {
            @extend %btn-basic;
            @extend %flex-row;
            @extend %label;
            gap: 8px;
            font-size: 12px;
            border-radius: 500px;
            padding: 8px 12px;
            outline: none;
            border: $border-darkest;
            background: white;
            color: $darkest;

            &.selected {
              color: white;
              background: $darkest;
            }

            @include md-down {
              font-size: 12px;
              /* padding: 10px 16px; */
            }
          }

          .settings-section-list {
            @extend %flex-col;
            gap: 10px;
          }

          a {
            color: $light;
          }

          span.link {
            @extend %btn-basic;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

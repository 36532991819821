@import '../../styles/shared.scss';

.requires-non-shopper-login-outer-container {
  font-family: $font-body;
  .requires-non-shopper-login-inner-container {
    @extend %flex-col-center;
    width: 90vw;
    max-width: 800px;
    margin: 128px auto 0;
    font-size: 14px;
    color: $dark;

    .header {
      font-family: $font-header;
      font-size: 24px;
      font-weight: 300;
      text-align: center;
    }
    .subheader {
      margin-top: 12px;
      font-size: 14px;
      line-height: 1.4;
      color: $medium;
      text-align: center;
      max-width: 600px;
    }

    .upgrade-btn {
      @extend %btn-darkest-new;
      margin-top: 16px;
      padding: 12px 24px;
      font-size: 10px;
    }
  }
}

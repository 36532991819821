@import '../../../styles/shared.scss';

.header-search-container {
  position: relative;
  border: 1.148px solid rgba(255, 255, 255, 0.05);
  background: linear-gradient(100deg, rgba(255, 255, 255, 0.15) -0.83%, rgba(255, 255, 255, 0.05) 100.32%), rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  transition: transform 0.25s;

  .active-searching-fade {
    position: fixed;
    top: -50vh;
    right: -50vw;
    height: 200vh;
    width: 200vw;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s;

    &.searching {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.is-in-search-mode {
    height: 40px;
    min-height: 40px;

    input {
      max-width: 440px;
    }
  }

  &:not(.is-in-search-mode) {
    height: 40px;
    min-height: 40px;
    width: 40px;
    max-width: 40px;
    cursor: pointer;

    .search-icon {
      width: 100%;
      height: 100%;
      left: 0;
    }

    input {
      max-width: 0px;
      opacity: 0;
      pointer-events: none;
      transform: translateX(200px) scaleX(0);
      transform-origin: right;
    }
  }

  form {
    @extend %flex-row-middle;
    position: relative;

    &.on-dark {
      color: white;

      input {
        background-color: $dark;
        color: white;

        &::placeholder {
          color: $lightest;
        }
      }
    }

    .search-icon {
      @extend %flex-center;
      position: absolute;
      left: 23px;
      top: 0;
      height: 100%;
      pointer-events: none;
      z-index: 3;
      color: white;

      svg {
        font-size: 12px;
      }
    }

    input {
      font-family: $font-body-new;
      position: relative;
      border: none;
      z-index: 2;
      padding: 10px 24px 10px 44px;
      outline: none;
      font-size: 14px;
      width: 40vw;
      min-width: 300px;
      font-weight: 500;
      color: white;
      background-color: transparent;
      transition: transform 0.25s;

      @include md-down {
        font-size: 16px;
        width: calc(100vw - 40px);
      }

      svg {
        font-size: 14px;
      }

      &::placeholder {
        color: $offwhite;
        background-color: transparent;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .input-actions-container {
      z-index: 2;
    }
  }

  .search-results-container {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    z-index: 2;

    @include md-down {
    }
  }
}

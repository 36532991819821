@import '../../../styles/shared.scss';

.header-subpage-panel {
  display: none;
  padding-top: 12px;
  position: absolute;
  top: 100%;
  right: 24px;
  z-index: 10;

  .header-subpage-panel-inner {
    @extend %animation-fade;
    border-radius: 12px 12px 12px 12px;
    background-color: white;
    overflow: hidden;
    min-width: 720px;
    padding: 42px;
    box-shadow: $shadow-dark;

    @include xxl {
      min-width: 800px;
    }

    // Animations
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-duration: 500ms;
    animation-name: animateIn;
    transform-origin: top right;

    .subpages {
      @extend %flex-row;
      flex-wrap: wrap;

      .subpage {
        width: 33.333%;
        padding: 18px;
        color: $darkest;
        border-radius: 10px;
        transition: background-color 200ms ease-in-out;

        &:hover {
          background-color: $prettymuchwhite;
        }

        .subpage-header {
          @extend %flex-row-middle;
          padding-bottom: 16px;
          margin-bottom: 16px;
          gap: 16px;
          border-bottom: $border-lighter;

          img {
            width: 18px;
            height: 18px;
          }

          .subpage-title {
            flex: 1;
            color: $darkest;
            text-transform: none;
            letter-spacing: 0;
            font-size: 18px;
            font-weight: 500;
            white-space: normal;
            flex-wrap: wrap;
            line-height: 1.2;
          }
        }

        .subpage-description {
          color: $dark;
          text-transform: none;
          line-height: 1.4;
          letter-spacing: 0;
          font-size: 14px;
          font-weight: 300;
          white-space: pre-wrap;
        }
      }
    }
  }
}

@keyframes animateIn {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@import '../../../styles/shared.scss';

.curator-group-modal-empty-search-results {
  @extend %flex-center-vertical;
  width: 100%;
  background-color: $offwhite;
  padding: 24px 48px;
  min-height: 200px;

  .header {
    font-size: 18px;
    color: $darkest;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .subheader {
    font-size: 12px;
    color: $medium;
    line-height: 1.4;
    text-align: center;
    max-width: 440px;

    a {
      color: $primary;
      font-weight: 500;
    }
  }
}

@import '../../styles/shared.scss';

.modal-outer-container.curator-group-modal-outer {
  .curator-group-modal-inner {
    @include md {
      width: 90vw !important;
      max-width: 900px !important;
      min-height: 360px !important;
    }

    .curator-group-modal-content {
      @extend %flex-col;
      position: relative;
      background-color: white;
      gap: 24px;
      padding: 60px 24px;
      border-radius: 10px;
      min-height: 360px;

      @include md {
        padding: 60px 64px;
      }

      .curator-group-modal-actions {
        @extend %flex-row;
        position: absolute;
        top: 16px;
        right: 16px;
        gap: 12px;

        .done-btn {
          @extend %btn-darkest-new;
          font-weight: bold;
          padding: 4px 12px;
          font-size: 10px;
        }

        .close-icon {
          font-size: 24px;
          color: $dark;
          cursor: pointer;

          &:hover {
            color: $darkest;
          }
        }
      }

      .curator-group-modal-divider {
        text-align: center;
        padding: 24px 0;
        font-size: 12px;
        color: $darker;
        font-weight: 500;
      }

      .curator-group-modal-more-results {
        @extend %flex-center;
        font-size: 12px;
        color: $medium;
        font-style: italic;

        .clickable {
          @extend %btn-basic;
          font-weight: bold;
          color: $darkest;
          margin-left: 4px;
        }
      }
    }
  }
}

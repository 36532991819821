@import '../../styles/shared.scss';

.smart-image {
  opacity: 0;
  transition: opacity 1s, transform 0.25s;

  &.loaded {
    opacity: 1;

    &.grow-in {
      transform: scale(1);
    }
  }

  &.grow-in {
    transform: scale(0.95);
  }

  &:hover {
    transform: scale(1.05);
  }
}

@import '../../styles/shared.scss';

.my-curators-sections {
  @extend %flex-col;
  gap: 64px;
  padding: 0 24px;

  .curators-section {
    .section-title-container {
      @extend %flex-row-middle-spread;
      margin-bottom: 28px;
      gap: 24px;

      @include md-down {
        flex-direction: column;
        align-items: flex-start;
      }

      .section-title {
        font-size: 36px;
        font-family: $font-header;
        font-weight: 300;
      }

      .section-controls {
        @extend %flex-row-middle;
        gap: 12px;

        .action-btn {
          @extend %flex-row-middle;
          @extend %btn-white-new;
          gap: 8px;
          font-size: 11px;
          padding: 8px 12px 8px 16px !important;

          &.primary {
            @extend %btn-darkest-new;
          }

          img {
            max-width: 16px;
            max-height: 16px;
          }
        }
      }
    }

    .section-items {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;

      @include md {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@import '../../styles/shared.scss';

.discover-curators-container {
  @extend %flex-col;

  .discover-featured-shops {
    margin-bottom: 48px;
  }

  .discover-curators-header {
    @extend %flex-col-center;
    padding: 0 24px 0;
    padding-bottom: 40px;
    gap: 8px;

    h1 {
      font-size: 44px;
      line-height: 1;
    }

    h2 {
      font-size: 16px;
      line-height: 1.4;
    }

    @include sm {
      padding-bottom: 40px;
      h1 {
        font-size: 40px;
      }

      h2 {
        font-size: 16px;
      }
    }
    @include md {
      padding-bottom: 48px;
      h1 {
        font-size: 48px;
      }

      h2 {
        font-size: 16px;
      }
    }
    @include lg {
      padding-bottom: 56px;
      h1 {
        font-size: 56px;
      }

      h2 {
        font-size: 18px;
      }
    }
    @include xl {
      padding-bottom: 64px;
      h1 {
        font-size: 64px;
      }

      h2 {
        font-size: 20px;
      }
    }

    h1,
    h2 {
      margin: 0;
      text-align: center;
    }

    h1 {
      color: $darkest;
      font-weight: 400;

      i {
        font-family: $font-shop-italic;
        font-style: italic;
        font-weight: 500;
      }
    }

    h2 {
      font-weight: 300;
      color: $darkest;
    }
  }

  .discover-results-error-container {
    @extend %flex-center-vertical;
    padding: 80px 48px;
    background-color: $white;
    border-radius: 10px;
    gap: 20px;

    .discover-results-error {
      @extend %flex-center-vertical;
      gap: 24px;
      margin: 0;

      .error-msg {
        font-size: 16px;
        font-weight: 300;
        color: $darkest;
      }

      .retry-btn {
        @extend %btn-outline-new;
        padding: 16px 36px;
        font-size: 12px;
      }
    }
  }

  .discover-results {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    margin-top: 24px;
    padding: 0 8px;
    gap: 8px;

    @include sm {
      padding: 0 8px;
    }

    @include md {
      padding: 0 24px;
      gap: 12px;
      grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    }

    @include lg {
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    }

    .discover-curator-group {
      grid-column: span 2;
      aspect-ratio: 2 / 1;
    }

    .discover-curator {
      aspect-ratio: 1;
    }
  }
}

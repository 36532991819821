@import '../../../styles/shared.scss';

.onboarding-wizard-badge-outer-container {
  @extend %flex-center;
  margin-left: 12px;
  position: relative;
  z-index: 2;

  @include md-down {
    display: none;
  }

  .onboarding-wizard-badge-inner-container {
    .onboarding-status {
      cursor: pointer;
      background-color: $dark;
      color: white;
      border-radius: 24px;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: 500;
      transition: opacity 0.2s;
      white-space: nowrap;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

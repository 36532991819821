@import '../../styles/shared.scss';

.product-main-section-container {
  @extend %flex-row;

  @include md-down {
    flex-direction: column-reverse;
  }

  .product-data-outer-container {
    @extend %flex-row;
    padding-top: max(10vh, $shoppingNavHeight);
    max-height: 95vh;
    justify-content: flex-end;
    width: 50vw;

    @include md-down {
      padding-top: 0;
      width: 100vw;
    }

    .product-data-inner-container {
      @extend %flex-col;
      justify-content: center;
      gap: 64px;
      flex: 2;
      padding: 48px 48px 96px 0;
      max-width: calc(1240px / 2);
      padding-left: 24px;

      @include md-down {
        padding: 48px 20px;
        max-width: 100vw;
        gap: 24px;
      }

      .fade-in {
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-duration: 500ms;
        animation-name: fadeIn;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        opacity: 0;

        &.first {
          animation-delay: 0.1s;
        }
        &.second {
          animation-delay: 0.2s;
        }
        &.third {
          animation-delay: 0.3s;
        }
        &.fourth {
          animation-delay: 0.4s;
        }
        &.fifth {
          animation-delay: 0.6s;
        }
      }

      .main {
        @extend %flex-col;
        gap: 2px;
        position: relative;

        .metadata {
          @extend %flex-row-middle;
          font-size: 15px;
          gap: 4px;
          color: $medium;

          &.loading {
            min-height: 15px;
            width: 80px;
            border-radius: 4px;
            background-color: $prettymuchwhite;
            opacity: 0.52;
          }

          .clickable {
            @extend %btn-basic;
            cursor: pointer;
            color: $medium;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
        .title {
          font-size: 20px;
          line-height: 1.4;
          font-weight: 500;
          letter-spacing: -2%;
          color: $darkest;
          margin: 0;

          &.loading {
            min-height: 40px;
            min-width: 240px;
            width: 200px;
            border-radius: 4px;
            background-color: $prettymuchwhite;
            opacity: 0.2;
          }
        }

        .my-circle {
          @extend %btn-basic;
          font-size: 12px;
          color: $medium;
          font-weight: 500;
        }

        .favorite-icon {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .body {
        max-width: 480px;

        .description {
          line-height: 1.4;
          font-weight: normal;
          margin: 0;

          &.emphasize {
            font-size: 25px;
            color: $darkest;
            font-family: $font-header;
            font-weight: 300;

            &.loading {
              min-height: 70px;
              width: 80%;
              border-radius: 4px;
              background-color: $prettymuchwhite;
              opacity: 0.2;
            }
          }

          &.normal {
            margin-top: 8px;
            font-size: 15px;
            color: $darkest;
            letter-spacing: 0.1px;

            &.loading {
              min-height: 105px;
              width: 80%;
              border-radius: 4px;
              background-color: $prettymuchwhite;
              opacity: 0.52;
            }
          }
        }
      }

      .links-and-favorite {
        @extend %flex-row-middle;
        margin-top: 24px;
        gap: 16px;

        &.loading {
          min-height: 40px;
          width: 60%;
          border-radius: 4px;
          background-color: $prettymuchwhite;
          opacity: 0.2;
        }

        .link {
          @extend %btn-darkest-shop;
          font-size: 14px;
          position: relative;
          padding: 16px 36px;

          .time-ago {
            position: absolute;
            top: calc(100% + 8px);
            right: 0;
            font-size: 10px;
            color: $dark;
            letter-spacing: 0;
            font-weight: 300;
          }
        }

        .favorite-btn {
          @extend %flex-row-middle;
          gap: 8px;
          border: $border-lightest;
          padding: 16px 36px;
          border-radius: 4px;
          cursor: pointer;
          background-color: white;
          transition: border-color 0.25s;

          &.favoriting {
            .label {
              opacity: 0.6;
            }
          }

          .label {
            font-size: 14px;
            font-weight: 500;
            color: $dark;
            transition: opacity 0.25s;
          }

          .icon {
            font-size: 12px;
            color: $darker;
            transform: translateY(1px);
          }

          &.favorited {
            border-color: $medium;
          }
        }
      }

      .secondary {
      }
    }
  }

  .product-images-container {
    @extend %flex-col;
    align-items: flex-start;
    flex: 1;
    width: 50vw;
    max-height: 95vh;

    @include md-down {
      width: 100vw;
      max-height: 60vh;
      flex-direction: row;
      background-color: white;
    }

    .primary-image-container {
      @extend %flex-center;
      overflow: hidden;
      height: 100%;
      background-color: $prettymuchwhite;
      aspect-ratio: 1;
      width: 100%;
      height: 75vh;
      min-height: 75vh;
      position: relative;
      border-bottom: $border-white;
      border-left: $border-white;

      @include md-down {
        min-width: 75vw;
        height: 60vh;
        min-height: 60vh;
      }

      &.cover {
        img.primary-image {
          object-fit: cover;
        }
      }

      &.contain {
        img.primary-image {
          object-fit: contain;
        }
      }

      img.primary-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 1s;
        // transition: opacity 2.5s;
        opacity: 0;

        &.loaded {
          opacity: 1;
        }
      }

      .admin-controls {
        @extend %flex-row-middle;
        gap: 4px;
        position: absolute;
        bottom: 12px;
        right: 12px;

        .control-btn {
          @extend %btn-offwhite-new;
          font-size: 10px;

          &.delete {
            @extend %btn-error-new;
          }

          &.primary {
            @extend %btn-darkest-new;
          }

          &.disabled {
            @extend %btn-offwhite-new;
          }
        }
      }
    }

    .other-images {
      @extend %flex-row;
      height: 20vh;
      min-width: 10px;
      width: 100%;
      overflow-x: auto;

      @include md-down {
        flex-direction: column;
        height: 60vh;
      }

      .product-main-section-other-image-container {
        // On outer level to allow for sorting
      }
    }
  }
}

.product-main-section-other-image-container {
  @extend %flex-row;
  background-color: white;
  border: $border-white;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  height: 20vh;
  min-height: 20vh;
  width: 20vh;
  min-width: 20vh;
  aspect-ratio: 1;
  background-color: $prettymuchwhite;

  @include md-down {
    flex-direction: column;
    height: 25vw;
    min-height: 25vw;
    width: 25vw;
    min-width: 25vw;
  }

  @for $i from 1 through 50 {
    &:nth-child(#{$i}) img.other-image {
      // animation-delay: 0.4s + ($i * 0.1s); // Match with loading on JS for image
      animation-delay: 0.3s + ($i * 0.15s); // Match with loading on JS for image
    }
  }

  &.featured {
    border-color: $lightest;
  }

  &.loading {
    background-color: $prettymuchwhite;
  }

  img.other-image {
    width: 20vh;
    height: 20vh;
    aspect-ratio: 1;
    object-fit: cover;
    opacity: 0;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-duration: 500ms;
    animation-name: fadeIn;
    animation-delay: 0s;
    animation-fill-mode: forwards;

    @include md-down {
      width: 25vw;
      height: 25vw;
    }
  }

  .actions {
    @extend %flex-row-middle;
    gap: 3px;
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;

    .action {
      &.delete {
        @extend %flex-center;
        background-color: $error;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: white;
      }
    }

    .sortable-handle {
      opacity: 1;
      position: relative;
      top: 0;
      right: 0;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@import '../../styles/shared.scss';

.shop-product-result {
  @extend %flex-col;
  background-color: white;
  position: relative;
  overflow: hidden;

  &:hover {
    .image-arrows,
    .my-shop-actions {
      opacity: 1;
    }
  }

  .image-container {
    border-radius: 10px;
    aspect-ratio: 1;
    transition: background-color 0.25s;
    text-decoration: none;
    padding: 24px 8px 0;

    @include sm {
      padding: 30px 20px 0;
    }

    @include md {
      padding: 36px 24px 0;
    }

    &:hover {
      img {
        transform: scale(0.98);
        transition-delay: 0s !important;
      }
    }

    &.img-full-cover {
      padding: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        img {
          transform: scale(1.02);
          transition-delay: 0s !important;
        }
      }
    }

    img {
      width: 100%;
      margin: 0 auto;
      transition: opacity 1s, transform 0.25s;
      object-fit: contain;
      aspect-ratio: 1;
      opacity: 0;

      &.is-hidden {
        filter: grayscale(100%);
      }

      &.image-loaded {
        opacity: 1;
      }
      &.column-0 {
        transition-delay: 0s;
      }
      &.column-1 {
        transition-delay: 0.1s;
      }
      &.column-2 {
        transition-delay: 0.2s;
      }
    }
  }

  .image-arrows {
    @extend %flex-row-middle-spread;
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    padding: 24px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s;
    z-index: 2;

    @include md-down {
      display: none;
    }

    .arrow {
      @extend %flex-center;
      width: 30px;
      height: 30px;
      pointer-events: auto;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      box-shadow: $shadow-lighter;
      opacity: 0.7;
      color: $darkest;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .my-shop-actions {
    position: absolute;
    top: 24px;
    right: 24px;
    opacity: 0;
    transition: opacity 0.25s;
    z-index: 2;

    .make-primary-image {
      @extend %btn-basic;
      padding: 4px 12px;
      font-size: 12px;
      background-color: white;
      border: $border-offwhite;
      border-radius: 4px;
      font-weight: 500;
    }
  }

  .hidden-overlay-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 24px;

    &.fetching {
      opacity: 0;
    }

    .hidden-overlay-inner {
      @extend %flex-center-vertical;
      background-color: rgba(255, 255, 255, 0.85);
      padding: 36px;
      border-radius: 4px;

      @include md-down {
        padding: 36px 12px;
      }

      .hidden-title {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 4px;
      }

      .hidden-subtitle {
        font-size: 12px;
        color: $medium;
        line-height: 1.4;
        text-align: center;
      }

      .actions {
        @extend %flex-row;
        gap: 4px;
        margin-top: 16px;

        .action {
          &.primary {
            @extend %btn-darkest-shop;
            padding: 4px 12px;
            font-size: 12px;
          }
          &.secondary {
            @extend %btn-offwhite-shop;
            padding: 4px 12px;
            font-size: 10px;
          }
        }
      }
    }
  }

  .data-containers {
    z-index: 2;
    padding: 24px 12px 12px;

    @include sm {
      padding: 20px 20px 20px;
    }

    @include md {
      padding: 24px 24px 24px;
    }

    &.img-full-cover:not(.fetching) {
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.15), transparent);
        z-index: -1;
        // transition: opacity 0.25s;
        transition: opacity 2.5s;
        opacity: 0;
        pointer-events: none;
      }

      &.image-loaded {
        &::before {
          opacity: 1;
        }
      }
    }

    .curator-data-container {
      @extend %flex-row-spread;
      margin-top: 12px;

      .promoted-by {
        @extend %flex-row-middle;
        gap: 2px;

        &.fetching {
          .curator {
            img {
              opacity: 0;
            }
          }
        }

        .curator {
          transition: opacity 0.25s;
          border-radius: 50%;
          overflow: hidden;
          width: 20px;
          height: 20px;
          background-color: $offwhite;

          @include md-down {
            border: $border-white;
          }

          .image {
            width: 100%;
            height: 100%;
          }
          img {
            opacity: 1;
            width: 20px;
            height: 20px;
          }

          &:nth-child(2) {
            @include md-down {
              margin-left: -10px;
            }
          }

          &:nth-child(3) {
            @include md-down {
              display: none;
            }
          }
        }

        .extra-stats {
          font-size: 10px;
          color: $darkest;
          margin-left: 4px;

          &.img-full-cover:not(.fetching) {
            color: white;
          }
        }

        .found-date-single-curator {
          margin-left: 4px;

          &.img-full-cover:not(.fetching) {
            .name {
              color: white;

              &.clickable:hover {
                color: $white;
              }
            }

            .when {
              color: $light;
            }
          }

          .name {
            font-weight: 500;
            font-size: 10px;
            color: $darkest;

            &.clickable {
              cursor: pointer;
              &:hover {
                text-decoration: underline;
                color: $black;
              }
            }
          }
          .when {
            font-size: 9px;
            color: $medium;
          }
        }

        .total-clicks,
        .total-curators {
          font-size: 10px;
          margin-left: 4px;
          font-weight: 500;
          color: $medium;

          &.fetching {
            opacity: 0.2;
          }

          &.img-full-cover:not(.fetching) {
            color: white;

            .number {
              color: white;
            }
          }

          .number {
            font-size: 10px;
            font-weight: bold;
            color: $darkest;
          }
        }
      }

      .actions-container {
        @extend %flex-row;
        gap: 8px;

        .action {
          cursor: pointer;
          transition: opacity 0.25s, transform 0.25s;

          &.fetching {
            opacity: 0;
          }
        }

        .favorite {
          font-size: 18px;
          color: $darkest;
          cursor: pointer;
          opacity: 0.3;
          transition: opacity 0.25s, transform 0.25s;

          &:hover {
            opacity: 1;
          }

          &.favoriting-animation {
            transform: scale(0.9);
          }

          &.favorited {
            color: $darkest;
            opacity: 1;
          }

          &.fetching {
            opacity: 0;
          }
        }
      }
    }

    .product-data-container {
      @extend %flex-col-spread;
      margin-top: 12px;
      gap: 12px;
      flex: 1;

      &.fetching {
        .main .brand,
        .main .category,
        .main .title,
        .secondary .price {
          background-color: $offwhite;
          color: transparent;
        }

        .main .bullet {
          color: $lightest;
        }
      }

      &.img-full-cover:not(.fetching) {
        .main .brand,
        .main .category,
        .main .title,
        .main .bullet,
        .secondary .price {
          color: white;
        }
      }

      .main {
        @extend %flex-col-start;
        gap: 4px;

        .filters {
          @extend %flex-row-middle;
          font-size: 11px;
          color: $darkest;

          .brand,
          .category {
            border-radius: 4px;
          }

          .bullet {
            padding: 0 1px;
            margin: 0 1px;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
        .title {
          font-size: 15px;
          line-height: 1.4;
          color: $darkest;
          font-weight: bold;
          border-radius: 4px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .clickable {
          @extend %btn-basic;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .secondary {
        @extend %flex-col-start;

        .price {
          font-size: 12px;
          color: $light;
          border-radius: 4px;
          min-width: 16px;
        }
      }
    }
  }
}

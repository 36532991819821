@import '../../styles/shared.scss';

.curator-group-card-outer-link {
  text-decoration: none;
  color: inherit;
}

.curator-group-card-container {
  .curator-group-card {
    @extend %flex-col-spread;
    gap: 8px;
    background-color: $darkest;
    position: relative;
    min-width: 320px;
    aspect-ratio: 2 / 1;
    overflow: hidden;
    border-radius: 10px;

    &:hover {
      .background-image img {
        // filter: brightness(0.8);
        transform: scale(1.05);
      }

      .actions .action.show-action-on-card-hover {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .background-image {
      @extend %flex-center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      &.is-loading {
        background-color: $darker;

        img {
          opacity: 0;
        }
      }

      &.hidden-except-admins {
        img {
          opacity: 0.2;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: filter 0.25s, opacity 0.25s, transform 0.25s;
        opacity: 0.6;
      }

      .hidden-except-admins-overlay {
        @extend %label;
        position: absolute;
        z-index: 1;
        font-size: 10px;
      }
    }

    .actions {
      @extend %flex-row-end;
      gap: 8px;
      padding: 16px 16px 32px;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      // background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));

      &.is-loading {
        .action {
          opacity: 0.5;
        }
      }

      .action {
        @extend %flex-center;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        background-color: transparent;
        color: white;
        font-size: 16px;
        background-color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
        transition: opacity 0.25s;
        position: relative;

        &:not(.disabled):not(.is-check):hover {
          background-color: rgba(255, 255, 255, 0.6);
        }

        &.is-check {
          background-color: $successFaded;
        }

        svg {
          color: white;
        }

        &:hover {
          .explainer {
            opacity: 1;
          }
        }

        .explainer {
          @extend %flex-center;
          position: absolute;
          top: 100%;
          margin-top: 4px;
          text-align: center;
          left: 0;
          width: 100%;
          white-space: nowrap;
          font-size: 10px;
          font-weight: bold;
          opacity: 0;
          transition: opacity 0.25s;
        }

        &.disabled {
          cursor: default;

          svg {
            color: $light;
          }
        }

        &.show-action-on-card-hover {
          opacity: 0;
          transition: opacity 0.25s;
          pointer-events: none;
        }
      }
    }

    .badge-container {
      @extend %flex-center;
      position: absolute;
      top: 24px;
      width: 100%;

      .badge {
        background-color: $darkest;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.3);
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .content {
      @extend %flex-center-vertical;
      flex: 1;
      padding: 24px 12px 12px; // Slightly down
      z-index: 1;

      &.clickable {
        cursor: pointer;
        transition: opacity 0.25s;

        &:hover {
          opacity: 0.8;
        }
      }

      &.is-loading {
        .title,
        .curated-by,
        .metadata {
          opacity: 0.1;
        }
      }

      .curated-by {
        font-family: $font-shop-italic;
        color: $offwhite;
        font-style: italic;
        font-size: 16px;

        @include sm {
          font-size: 26px;
          font-size: 16px;
        }

        @include md {
          font-size: 28px;
          font-size: 18px;
        }
      }

      .title {
        font-family: $font-header;
        font-size: 24px;
        color: white;
        font-weight: 500;
        margin-top: 4px;

        @include sm {
          font-size: 26px;
        }

        @include md {
          font-size: 28px;
        }
      }
      .metadata {
        font-size: 12px;
        color: $offwhite;
        margin-top: 4px;
      }

      .title,
      .curated-by,
      .metadata {
        transition: opacity 0.25s;
        text-align: center;
      }
    }

    .action-icon {
      @extend %btn-basic;
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 24px;
      color: $darkest;
    }
  }
}

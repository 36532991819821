@import '../../../styles/shared.scss';

.add-pin-modal-merchant-payout-tile-outer-container {
  padding: 0 12px;
  font-family: $font-body;

  .merchant-payout-tile {
    @extend %flex-row-middle-spread;
    background: $offwhite;
    border: $border-lightest;
    padding: 4px 8px;
    border-radius: 4px;

    .merchant-payout-logo-outer-container.left {
      @extend %flex-row-middle;

      .merchant-payout-logo-container {
        @extend %flex-center;
        width: 50px;
        height: 30px;
        margin-right: 12px;
        position: relative;

        &.empty {
          width: 30px;
          margin-left: 12px;
        }

        .loader {
          position: absolute;
          left: 0;
          top: -15px;
          width: 100%;
          height: 100%;
        }

        .merchant-payout-logo {
          width: 100%;
          height: 100%;
          object-fit: contain;

          &.empty {
            width: 40px;
            border-radius: 4px;
          }
        }
      }
    }

    .merchant-payout-msg {
      @extend %flex-row-middle;
      color: $dark;
      font-size: 13px;
      font-weight: bold;

      .merchant-payout-icon {
        @extend %flex-row-middle;
        margin-left: 4px;
        width: 20px;
        height: 20px;

        img {
          display: flex;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .alternative-rate-label {
    margin-top: 4px;
    text-align: right;
    font-size: 11px;
    color: $dark;
    cursor: pointer;

    svg {
      margin-left: 3px;
      font-size: 8px;
    }
  }

  .alternative-rates {
    margin-top: 4px;

    .alternative-rate {
      @extend %flex-row-middle;
      justify-content: flex-end;
      font-size: 13px;
      padding: 0 0 8px;
      cursor: pointer;
      text-decoration: underline;

      &.better {
      }
      &.worse {
        opacity: 0.3;
      }
      .payout {
        margin-right: 4px;
        font-weight: bold;
      }
    }
  }
}
